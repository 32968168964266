.menuItem {
  align-items: center;
  text-align: start;
  padding: 8px 16px;

  .menuItemText {
    margin-left: 8px;
  }

}
