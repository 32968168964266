.compare {
	position: relative;

	h1 {
		font-weight: normal;
	}

	.compareText {
		display: flex;
		flex-direction: column;
		min-height: 50vh;
		color: #939393;
		text-align: center;
		justify-content: center;
	}

	.image {
		cursor: move;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
		transition: opacity .5s ease;
		transform-origin: center center;
		will-change: transform;
	}
	.containerMediaFile {
		height: 100%;
		width: 100%;
		position: relative;
		font-size: 0;
		overflow: hidden;
		transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
	}
	.innerContainerMediaFile {
		display: block;
		height: 100%;
		position: relative;
		transform-origin: left top;
	}
	.theMediaFile {
		display: flex;
		align-items: center;
		text-align: center;
		width: 100%;
		height: 100%;

		img, canvas {
			cursor: move;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			transition: opacity .5s ease;
			transform-origin: center center;
			will-change: transform;
		}
	}
}