
.container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;

  .tag {
    margin: 8px 10px 0px 0px;
    .tagTitle {
      z-index: unset;
    }
    .tagTitleWrapper::after {
      z-index: unset;
    }
  }

  .addCollectionButton {
    margin-top: 8px;
  }
}
