.SkeletonList {
  .item {
    width: calc(100% - 48px);
    margin: 0 auto;

    :global(.picsioThemeLight) & {
      background-color: #f4f4f4;
    }
  }
}
