$avatarDarkTheme: #424242;
$avatarDarkThemeHover: #5A5A5A;
$avatarLightTheme: #e2e2e2;
$avatarLightThemeHover: #ccc;

.avatar {
	position: relative;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: flex-end;

	width: inherit;
	height: inherit;
	color: $avatarDarkTheme;
	box-shadow: inset 0 0 0 2px $avatarDarkTheme;
	line-height: 25px;
	text-align: center;

	&.avatarPicsio {
		background-color: $brandColor;
		color: #000 !important;
		box-shadow: none !important;
		align-items: center;
	}

	&.isAvatarTransparent {
		&::after {
			content: none;
		}
	}

	.tag & {
		box-shadow: none;
		z-index: 0;
		color: currentColor;
		box-shadow: inset 0 0 0 2px currentColor;
	}

	.svg-icon {
		color: inherit;
	}

	.icon-logoPicsio {
		width: 100%;
		font-size: 14px;
		border-radius: 50%;
	}

	.icon-avatar {
		font-size: 0.92em;
	}

	img {
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	&::after {
		content: '';
		z-index: -1;
		background-color: #ccc;
		position: absolute;
		top: 1px;
		left: 1px;
		bottom: 1px;
		right: 1px;
		border-radius: 50%;
	}

	&.isHover {
		color: $avatarDarkThemeHover;
		box-shadow: inset 0 0 0 2px $avatarDarkThemeHover;

		&::after {
			background-color: #fff;
		}
	}

	&.isActive {
		color: #242424;
		box-shadow: 0 0 0 2px $brandColor;
		background-color: $brandColor;

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	z-index: 1;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	border-radius: 50%;
		// 	border: 2px solid $brandColor;
		// }
	}
}

.picsioThemeLight {
	.avatar {
		color: $avatarLightTheme;
		box-shadow: inset 0 0 0 2px $avatarLightTheme;

		&::after {
			background-color: #fff;
		}

		&.isHover {
			color: $avatarLightThemeHover;
			box-shadow: inset 0 0 0 2px $avatarLightThemeHover;

			&::after {
				background-color: #fff;
			}
		}

		&.isActive {
			color: #fff;
			box-shadow: 0 0 0 2px $brandColorLightTheme;
			background-color: $brandColorLightTheme;
		}
	}


	.tag {
		.avatar {
			color: currentColor;
			box-shadow: inset 0 0 0 2px currentColor;
		}
	}
}
