.TranscriptList {
  position: relative;
  height: 100%;

  &__search-bar {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    padding: 16px 10px 24px 16px;
    background-color: #242424;

    .picsioThemeLight & {
      background-color: #ffffff;
    }
  }

  &__list,
  &__reader {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: calc(100% - 80px);
    overflow: hidden auto;
  }

  &__reader-text {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #ffffff;

    .picsioThemeLight & {
      color: #333;
    }

    & em {
      font-style: normal;
      background: rgba(255, 192, 0, 0.25);
      border-radius: 2px;

      .picsioThemeLight & {
        background: rgba(59, 148, 251, 0.25);
      }
    }
  }

  &__reader-text {
    margin: 0;
    font-weight: 300;
    line-height: 24px;
    padding: 0 16px;
  }
}
