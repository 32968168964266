.simpleDialog.WebsitePresetsDialog {



  .simpleDialogContent {
    margin: 0;
  }
  .simpleDialogContentInner {
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 25px 0px 2px;
  }

  .simpleDialogSubContent {
    padding: 10px 25px 20px 25px;
  }
}

.WebsitePresetsDialog {
  &__top-line,
  &__bottom-line {
    border: 1px solid #333;

    .picsioThemeLight & {
      border: 1px solid #d1d1d1;
    }
  }
}

