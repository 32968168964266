.tagsGroup {
  margin: 0 0 20px;
}

.groupTitle {
  display: flex;
  gap: 4px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-contrast-color);
  margin: 0 0 9px;
}
.text {
  flex: 1;
  font-size: 14px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  > div {
    margin: 0 !important;
    max-width: 95% !important;
  }
}
