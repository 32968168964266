#import {
	width: 0;
	height: 100%;
	position: fixed;
	top: 0;
	left: 50px;
	font-size: 14px;
	color: #999;
	background: #242424;
	box-sizing: border-box;
	overflow: hidden ;
	z-index: 7;
	transition: all .3s ease-in-out;

	&.showImportPanel {
		&-enter {
			width: 0;

			&-active,
			&-done, {
				width: calc(100vw - 50px);
			}
		}
		&-exit {
			width: calc(100vw - 50px);

			&-active {
				width: 0;
			}
		}
	}

	.forMobile {display: none;}
	.listFolderTree	{
		position: relative;
		flex: none;
		width: 280px;
		background: #242424;
		padding-top: 46px;

		ul li .nameFolder {height: 28px;}
	}

	.dropdown-body {
		max-height: 450px;
	}
	.importStatus {
		position: absolute;
		z-index: 20;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .7);
		display: flex;
		align-items: center;
		justify-content: center;

		.text {
			line-height: 1.1;
			color: #fff;
			position: relative;
			font-size: 24px;
			font-weight: 300;

			span {
				animation-name: blink;
				animation-duration: 1.4s;
				animation-iteration-count: infinite;
				animation-fill-mode: both;
				&:nth-child(2) {
					animation-delay: .2s;
				}
				&:nth-child(3) {
					animation-delay: .4s;
				}
			}
		}
	}

	.iconHolder {
		.icon-folder {
			top: -1px !important;
		}
	}
}

.importInner {
	position: absolute;
	top: 0;
	right: 0;
	width: calc(100vw - 50px);
	height: 100%;

	@include media('>=tablet') {
		display: flex;
		flex-direction: column;

		.importButtons {
			margin-top: auto;
		}
	}
}
.importTop {
	position: relative;
	height: 50px;
	line-height: 50px;
	padding-left: 25px;
	background: #000;
	font-size: 15px;

	.importHelpButton {
		position: absolute;
		top: 0;
		right: 50px;
		height: 50px;
	}

	.importHideView {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.importMainLeft {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.importInfo {
	padding: 10px 0 5px 5px;
	box-sizing: border-box;
}

.importMain {
	height: calc(100% - 50px);
	display: flex;
	align-items: stretch;
}
.importContent {
	position: relative;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: row;
}
.importAdditionalFields {
	width: 100%;
	max-width: 320px;
	overflow: hidden;
	padding: 20px 0 10px 10px;
	display: flex;
	flex-direction: column;
	order: 2;
	border-left: 2px solid #000;
	box-sizing: border-box;
	transition: all .2s ease-in;

	&.disabled {
	  max-width: 0;
	  pointer-events: none;
	  padding: 0;
	}
}
.importNumberFiles {
  box-sizing: border-box;
	flex: none;
	width: 100%;
  padding: 16px 7px 2px;
	line-height: 28px;
	font-size: 24px;
	color: #666;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.hideBtn {
		.btnRemoveImportFile {visibility: hidden;}
	}
	&.hidden {
		visibility: hidden;
	}
	.btnRemoveImportFile {
		cursor: pointer;
		padding: 5px;
		font-size: 10px;
		margin-right: 7px;

		&:hover {
			color: #fff;
		}
	}
}
.filesCountProgress {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 130px;
	line-height: 30px;
	font-size: 14px;
	color: #666;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	.errorsNumber {
		color: #fc0d1b;
	}
}
.importFieldsHolder {
	width: 100%;
	min-width: 308px;
	height: 100%;
	overflow: hidden auto;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

 .UICheckbox__label {
	font-size: 15px;
	line-height: 18px;
	color: #999999;
  }
}

.importFieldItem {
	//padding: 0 0 0 5px;
	//margin-bottom: 5px;
	margin-bottom: 15px;
	position: relative;
	width: 100%;

	&:last-child {
		flex: 1 1 auto;
	}

	.UIInput {
		margin-bottom: 0;
	}

	[type='text'] {
		height: 30px;
	}

	.dropdownMainWrapper.dropdown-focused + .icon-keyword {
		@include media('<desktop') {
			z-index: 0;
		}
	}

	> .icon-avatar {
		@include media('<desktop') {
			left: 9px;
			top: 17px;
		}
	}

	.UICheckbox,
  .PicsioInputControlLabel {
		margin-top: 15px;
	}
}

.importFiles {
	padding: 5px 0;
	max-height: calc(100% - 122px);
	flex: 1;
	overflow: hidden;
}

.importTotalSize {
	position: absolute;
	right: 15px;
	top: 0;
	font-size: 14px;
	line-height: 35px;
	padding: 0 5px;
	color: #fff;
	font-family: $base-font-mono;
	white-space: nowrap;
}

.importButtons {
	display: flex;
	position: relative;
	padding: 35px 15px 15px 10px;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: $shadowModalLightTheme;
	box-sizing: border-box;

	.picsioDefBtn {
		margin: 0 0 0 5px;
		padding: 0 30px;
		min-width: 0;

    .svg-icon { margin-right: 8px;}

    &.disable {
      pointer-events: all;
    }
	}

	.btnAddMore {
		position: relative;
		overflow: hidden;

		input {
			position: absolute;
			left: -150%;
			top: 0;
			opacity: 0.01;
			width: 250%;
			height: 100%;
			cursor: pointer;
			margin: 0;
			padding: 0;
			display: block;
		}
	}
}

.importButtonsGroup {
  display: flex;
  align-items: center;
  gap: 5px;

	* {
		vertical-align: top;
	}

	.btnOpenLiveSupport {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		margin-right: 15px;
	}
}

@media screen and (max-width: $mediaDevice) {
	#import {
		width: 100%;
		height: 100%;
		top: -100%;
		left: 0;

		opacity: 0;
		position: absolute;
		padding-bottom: 56px;
		// background-color: #000;
		transition: none;
								//height 0.3s ease-in-out,
								//left 0.3s ease,
								//width 0.3s ease,
								// opacity 0.3s ease,
								// // top 0.3s ease,
								// transform 0.3s ease;
		z-index: 11;

		// &.showImportPanel {
		// 	&-enter {
		// 		width: 100%;
		// 		top: -100%;

		// 		&-active,
		// 		&-done, {
		// 			width: 100%;
		// 			top: 0;
		// 		}
		// 	}
		// 	&-exit {
		// 		width: 100%;
		// 		top: 0;

		// 		&-active {
		// 			width: 100%;
		// 			top: -100%;
		// 		}
		// 	}
		// }
		&.fade {
			&-enter {
				width: 100%;
				top: -100%;
				opacity: 0;

				&-active,
				&-done, {
					width: 100%;
					top: 0;
					opacity: 1;
				}
			}
			&-exit {
				width: 100%;
				top: 0;

				&-active {
					width: 100%;
					top: -100%;
				}

				&-done {
					opacity: 0;
				}
			}
		}

		.importTop {
			visibility: hidden;
		}

		.importInner {
			position: static;
		}
	}
	.importInner {
		width: 100%;
	}

	.importFieldsHolder {
		flex-wrap: wrap;
	}
	.importFieldItem {
		padding: 0;
		flex: 1 0 100%;

		[type='text'] {
			height: 49px;
		}
	}
}

.importCustomFields {
	display: block;
	.labelItemSearchFilters {
		display: none;
	}
}

@include media('<tablet') {
	#import {
		.forMobile {
			display: initial;
		}
	}
	.importAdditionalFields {
		display: block;
		overflow: visible;
		padding-right: 10px;
		padding-top: 5px;

		&.disabled {
			overflow: hidden;
			width: 100%;
			max-width: 100%;
			height: 0;
			max-height: 0;
		}
	}
	.importNumberFiles {width: 100%;}
	.importFiles {
		max-height: calc(100% - 221px);
		min-height: 61px;
	}
	.importButtons {
		padding-left: 5px;
		padding-right: 10px;

		.picsioDefBtn {
			min-width: 0;
			padding: 0 9px;
		}
	}

	.importContent {
		flex-direction: column;
	}

	.importMainLeft {
		order: -1;
		height: auto;
		flex: 0 0 auto;
		max-height: 100%;

		.uploadInProgress & {
			height: 100%;
		}
	}

	.importAdditionalFields {
		margin: auto;
    max-width: 100%;
    border-left: 0;
	}
}

@media only screen and (max-width: 414px) {
	#import {

		.importButtons {
			flex-wrap: wrap;
			padding-left: 10px;

			.importButtonsGroup {
				flex: 1 0 100%;
				display: flex;
				flex-wrap: wrap;
				gap: 5px;
				margin-bottom: 5px;
			}

			.picsioDefBtn,
			.PicsioButton {
			  box-sizing: border-box;
				margin: 0;
				width: 49%;
				padding: 0 5px;

				.PicsioButton__label {
				  width: 100%
			  }
			}
		}
	}
}

.isRequiredIndicator {
	color: $orange;
	margin-left: 4px;

	sup {
		font-size: 11px;
	}
}

/*************************************/
/************ LIGHT THEME ************/
/*************************************/

.picsioThemeLight {
	#import {
		color: #666;
		background: #fff;

		.importButtons {
			&::before {
				background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.10));
			}
		}
	}
	.importTop {
		background: #f4f4f4;
		color: #666;
	}
	.importNumberFiles {
		.btnRemoveImportFile:hover {color: #000;}
	}
	.importTotalSize {
		color: #666;
	}
	.importHideView:hover {
		color: #000;
	}


	@include media('<desktop') {
		#import {
			background-color: #fff;
		}
	}

	.importAdditionalFields {
		border-left-color: #f4f4f4;
	}
}
