.innerCatalog,
.recursiveSearchPanel {
	transition: transform 0.4s ease, margin-bottom 0.4s ease;
	// transform: translate3d(0,0,0);
}
.recursiveSearchPanel {
	width: 100%;
	box-sizing: border-box;
	padding: 12px 40px 13px 20px;
	background-color: #252525;
	color: #fff;
	position: relative;
	z-index: 2;
	margin-top: 0;

	&::after {
		content: '';
		width: 100%;
		height: 13px;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: rgba(0,0,0,0);
	}

	&.isActive {
		background-color: $brandColor;
		color: #000;
	}

	.btnClose {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 8px;
		right: 11px;
		opacity: 0.26;
		color: inherit;
		cursor: pointer;
		font-size: 15px;
		width: 24px;
		height: 24px;

		&::before {
			text-align: center;
		}

		&:hover {
			opacity: 1;
		}
	}

	.slideCheckbox {
		flex: 0 0 25px;
		order: -1;
		margin: 0 15px 0 0;
	}

	.slideCheckboxChecked {
		background: rgba(0,0,0,.1);
		border-color: rgba(0,0,0,.05);

		.slideCheckboxSlider {
			background: #fff;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
		}
	}

	.slideCheckboxLabel {
		color: inherit;
		font-size: 14px;
	}
}

.picsioThemeLight {
	.recursiveSearchPanel {
		background: #fff;
		color: #000;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
		position: relative;
		z-index: 1;

		&.isActive {
			background-color: $brandColorLightTheme;
			color: #fff;
		}

		.slideCheckboxChecked {
			background: rgba(0,0,0,.2);
			border-color: rgba(0,0,0,.05);

			.slideCheckboxSlider {
				background: #fff;
				box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
			}
		}
	}
}
