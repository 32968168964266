.revisionFieldsDialog {
	.revisionField {
		margin-bottom: 30px;

		* {
			box-sizing: border-box;
		}
	}
	
	.revisionFieldLabel {
		margin-bottom: 5px;
		font-size: 16px;
	}

	.inputWithModifiedField + .inputWithModifiedField {
		margin-top: 10px;
	}

	.assetMarks {
		line-height: 1;
		margin: 0 -5px;

		> div {
			margin: 0 4px 10px;
		}
	}

	.overflowVisible {
		overflow: visible !important;
	}
}