.Chooser {
  display: flex;
  flex-direction: column;

  .header {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: #242424;

    :global(.picsioThemeLight) & {
      background-color: #f4f4f4;
    }

    .breadcrumbs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .logo {
      width: 14px;
      height: 12px;
      margin-right: 8px;
    }

    .account {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .userInfo {
      margin-right: 36px;
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      border-radius: 50%;
    }

    .buttonHelp {
      margin-left: 12px;
      cursor: help;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
