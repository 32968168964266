.simpleDialog.UnsupportedMediaDialog {
  .simpleDialogContent {
    margin: 0;
  }
  .simpleDialogContentInner {
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 25px 0px 2px;
  }

  .simpleDialogSubContent {
    padding: 15px 25px 20px 25px;
    display: flex;
    align-items: center;

    font-family: 'Fira Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ccc;

    &__icon {
      margin-right: 8px;
    }
  }
}
