@mixin h1-bold {
  margin-bottom: 0.75em;
  font-size: 30px;
  line-height: 32px;
  font-weight: bold;
  color: var(--title-color);

  &:not(:first-child) {
    margin-top: 2em;
  }

  @include media('<desktop') {
    font-size: 2em;
  }

  @include media('<tablet') {
    font-size: 1.8em;
  }
}

.titleMainBold {
  @include h1-bold;
}

h2 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 17px;
  color: var(--title-color);
  letter-spacing: -0.5px;
}
