.MediaMatchInfoBlock {
  padding: 24px;
  border: 1px solid #343434;
  background: #292929;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  :global(.picsioThemeLight) & {
    background-color: #f8f8f8;
    border: 1px solid #f2f2f2;

  }

  .text {
    margin-left: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: var(--secondary-contrast-color);

  }

  .link {
    flex-shrink: 0;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
  }
}
