.container {
  $root: &;
  position: relative;

  &.withoutImage {
    $border: 1px;

    margin: $border;
    background-clip: padding-box; /* !importanté */
    background-color: #292929;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1px; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(180deg, #343434 50%, #292929 100%);
    }

    :global(.picsioThemeLight) & {
      background-color: #f4f4f4;
      &::before {
        background: linear-gradient(180deg, #e6e6e6 50%, #f0f0f0 100%);
      }
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skeletonContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.textContent {
  padding: 16px 24px;
}

.title {
  margin: 0;
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 300;

  .withoutImage & {
    font-size: 20px;
    font-weight: 500;
  }
}

.description {
  margin: 0;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16.8px;
}

.helperLink {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  color: var(--primary-main-color);
  text-decoration: none;
}

.helperText {
  margin-left: 8px;
  font-size: 14px;
}
