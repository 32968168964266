.notificationPanel {
	transition: transform 0.4s ease, margin-bottom 0.4s ease, background-color 0.4s ease;
	transform: translate3d(0,0,0);
	width: 100%;
	box-sizing: border-box;
	padding: 12px 40px 13px 20px;
	background-color: #252525;
	color: #fff;
	position: relative;
	z-index: 1;
	margin-top: 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 300;
	
	.notificationPanelText {
		margin-right: 15px;
	}
	
	.picsioDefBtn {
		margin: -5px 15px -5px -13px;
		min-width: 30px;
		font-size: 16px;
	}
	
	&::after {
		content: '';
		width: 100%;
		height: 13px;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: rgba(0,0,0,0);
	}

	&.isClosed {
		background-color: #252525;
	}

	.btnClose {
		position: absolute;
		top: 50%;
		right: 11px;
		opacity: 0.26;
		color: inherit;
		cursor: pointer;
		font-size: 15px;
		width: 24px;
		height: 24px;
		line-height: 24px;
		margin-top: -12px;
		text-align: center;

		&::before {
			text-align: center;
		}

		&:hover {
			opacity: 1;
		}
	}
}

.picsioThemeLight {
	.notificationPanel {
		background: #fff;
		color: #000;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	}
}