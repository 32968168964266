.newFeatures {
  padding: 1px 8px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.23em;
  text-transform: uppercase;

  color: var(--primary-contrast-color);
  background: var(--primary-main-color);
  border-radius: 2px;
}
