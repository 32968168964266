.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-main-color);
  border-radius: 2px;
  color: var(--primary-contrast-color);
  padding: 2px 6px 2px 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-wrap: nowrap;
}
.icon {
  margin-right: 4px;
  margin-left: 2px;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: var(--primary-contrast-color);
    }
  }
}

.aiBadgeTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
}
