.toolbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  color: $gray;
  font-size: 16px;

  &Vertical {
    flex-direction: column;

    .toolbarGroup {
      flex-wrap: wrap;
    }
  }

  &Group {
    display: flex;
  }

  .toolbarVideoControl {
    @include media('>tablet') {
      display: none;
    }

    @include media('<=tablet') {
      &.createVideoThumb {
        font-size: 24px;
      }
    }
  }

  &:not(.toolbarVertical) {
    .toolbarGroup:last-child {
      margin-left: auto;
    }
  }

  &.toolbarVertical {
    .toolbarGroup:last-child:not(:only-child) {
      margin-top: auto;
    }
  }

  .setAsCurrentRevision {
    position: relative;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      left: 5%;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #1b1b1b;
      width: 90%;
    }

    &:hover {
      color: var(--primary-contrast-color);
      background: var(--primary-main-color);

      &::before {
        height: 0px;
      }
    }
  }

  .revisionVersion {
    display: flex;
    align-items: center;
    margin-right: 18px;
  }

  .revisionOptionLabel {
    display: flex;
    flex-direction: row;

    .revisionNumberContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;

      .revisionNumberCircle {
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        min-width: 24px;
        height: 24px;
        padding: 0 4px;
        line-height: 24px;
        border-radius: 50%;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        background: $brandColor;
      }
    }
  }

  .revisionOptionContainer {
    width: 100%;
    padding-bottom: 20px;

    .revisionAuthor {
      margin: unset;
      color: inherit;

      .additionalName {
        color: inherit;
      }

      .authorAdditionalName {
        color: inherit;
      }

      .authorAdditionalClassname {
        color: inherit;
      }
    }
  }

  &Button {
    width: 50px;
    height: 50px;
    min-width: 50px;
    position: relative;
    color: inherit;
    cursor: pointer;
    display: inline-flex;

    &.sync {
      width: 100%;
    }

    svg {
      margin: auto;
    }

    .giftBox {
      vertical-align: middle;
      margin: -6px 0 0 8px;
    }

    &.isAvatar {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: none;
      }
    }

    .PicsioAvatar {
      width: 25px;
      height: 25px;

      .Safari & {
        overflow: hidden;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      color: inherit;
    }

    &.viewed::after {
      content: '';
      position: absolute;
      top: 20%;
      left: 65%;
      z-index: 2;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: radial-gradient(50% 50% at 50% 50%, #ffc000 0%, #fdb600 100%);
    }

    &:hover {
      color: var(--secondary-contrast-color);
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;

      &:hover {
        color: inherit;
      }
    }

    &.logoPicsio {
      &.disabled {
        opacity: 1;
        pointer-events: none;
      }
    }

    &.color {
      color: #000;
      background: $brandColor;
    }

    &.active {
      color: #fff;

      &::before {
        background: $light-lightblack;
      }
    }

    &.activeColor {
      color: $brandColor;
      // &::before {
      // }
    }

    &.drop-active {
      color: #fff;
    }

    &.backlight {
      color: $brandColor;

      // &::before {
      // }
    }

    .badge {
      position: absolute;
      left: 28px;
      top: 6px;
      background: $brandColor;
      color: #000;
      font-size: 10px;
      font-family: 'fira_monoregular', 'Fira Sans', Helvetica, Arial, sans-serif;
      border-radius: 8px;
      line-height: 16px;
      min-width: 15px;
      height: 15px;
      padding: 0 3px;
      text-align: center;
      box-sizing: border-box;

      .toolbarCatalog & {
        top: 24px;
        z-index: 1;
      }
    }

    &.notClickable {
      cursor: default;
    }
  }

  .logoutLink {
    height: 50px;
    line-height: 50px;
    position: relative;
    color: inherit;
    cursor: pointer;
    padding: 0 15px;

    .icon-logout {
      margin: -2px 6px 0 0;
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: $brandColor;
    }
  }

  // Preview main top toolbar
  &PreviewTop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
  }

  &PreviewLeft {
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 50px;

    @include media('<tablet') {
      top: auto;
      height: 50px;
      width: 100%;
      flex-flow: row nowrap;

      overflow-x: auto;
      overflow-y: hidden;

      .toolbarGroup {
        flex-wrap: nowrap;
      }
    }
  }

  // icons customization size
  &Button {
    .icon-lightboard,
    .icon-backToCatalog {
      font-size: 20px;
    }

    .icon-custom {
      font-size: 18px;
    }

    .PicsioIcon {
      margin: auto;
    }
  }

  &DropdownItem {
    .icon-dollar {
      font-size: 18px;
    }

    .giftBox {
      vertical-align: middle;
      margin: -6px 0 0 8px;
    }
  }

  .multipagePaging {
    margin: 0 15px;
    white-space: nowrap;
    font-size: 14px;

    b {
      font-weight: normal;
      color: #fff;
    }

    @include media('<tablet') {
      display: none;
    }
  }

  .icon-download {
    font-size: 14px;
  }

  .pProgressWrapper {
    left: 4.2px;
    top: 4.2px;
    right: auto;
    bottom: auto;
    transform: scale(0.4);
    transform-origin: 0 0;
    z-index: 1;
    pointer-events: none;
    background: none;

    * {
      box-sizing: content-box;
    }
  }

  &Counter {
    position: absolute;
    top: 10px;
    right: 6px;
    z-index: 3;
    width: auto;
    min-width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 10px;
    background-color: $brandColor;
    color: #333;
    text-align: center;
    border-radius: 50%;

    &:empty {
      display: none;
    }
  }
}

.selectedAssetsWrapper {
  min-width: 175px;
}

.selectedAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2vw;

  .selectedAssetsText {
    margin-left: 10px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary-main-color);
    cursor: pointer;
    text-decoration: underline;
  }
}

.assetName {
  @include toolbarTitle;

  &Wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 0 0 15px;
    height: 50px;
    margin-left: 10px;

    .icon-previewTitle {
      display: none;
      color: #444;
    }
    .UIInput {
      margin: 0;
    }

    &.previewAssetNameWrapper {
      margin-left: unset;
      display: flex;
      justify-content: center;
    }
  }
  margin-right: 16px;
  &.isExternalAsset {
    margin-right: unset;
  }
}

// Catalog toolbar
.toolbarCatalogTop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  height: 50px;

  .proofingHelpIcon {
    width: 50px;
    color: $picsioYellowColor;
  }

  .wrapperBreadcrumbs {
    display: block;
  }
}

.toolbarButton.archive {
  svg {
    width: 18px;
    height: 18px;
  }
}

.toolbarButton.logoPicsio {
  background: $brandColor;
  color: #000;
  font-size: 22px;
  transition: background-color 0.3s ease;
}

.brandedLogo {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  margin: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  &.rounded {
    background-size: cover;
    border-radius: 50%;
  }
}

.toolbarCatalogLeft,
.toolbarScreenLeft {
  position: fixed;
  top: 50px;
  left: 0;
  width: 50px;
  z-index: 6;
  height: calc(100% - 50px);
  background: #000;

  .icon-toolbarDownload {
    &::after {
      content: '';
      width: 30px;
      height: 1px;
      display: block;
      background: $bgColor;
      position: absolute;
      left: 10px;
      top: 0;
    }
  }

  @include media('<desktop') {
    border-right: 1px solid #333;
    width: 51px;

    .toolbarButton {
      margin-bottom: -1px;
      color: #939393;

      &::before {
        content: '';
        background: inherit;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }

      &.active {
        color: #fff;

        &::before {
          border-top-color: #333;
          border-bottom-color: #333;
          border-right: 1px solid #000;
          width: 51px;
          background: #000;
          z-index: -1;
        }
      }
    }

    .wrapperPicsioApp:not(.isLeftMenuActive) & {
      display: none;
    }
  }
}

.toolbarScreenLeft {
  .toolbarGroup {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
  }
}

// Screen toolbar
.toolbarScreenTop {
  position: relative;
}

.drop-place-indicator {
  display: none;
}

.toolbarDropdown {
  cursor: default;
  min-width: 260px;
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  background: $bgColor;
  color: #ccc;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  font-family: $base-font;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;

  &.drop-active-left,&.drop-active-left-filters {
    left: auto;
    right: 0;
  }

  &.dropdownSort {
    min-width: 320px;

    .jobs-status-spinner {
      cursor: default;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: 60px 60px;
      background-color: rgba(var(--primary-contrast-color-rgb), 0.3);
      z-index: 1;
      margin: -10px 0 0 0px;
    }

    .customFieldsSortSelectorWrapper {
      margin-top: 11px;
      padding: 0px 16px;
      .dropdown {
        .PicsioPopper {
          // to fix popper bug
          transform: translate(0px, -39px) !important;
        }
      }
      .customFieldsSort {
        display: flex;
        flex-direction: column;
      }
    }
    .customFieldsSortInfo {
      display: flex;
      align-items: center;
      margin-top: 13px;

      .text {
        font-size: 12px;
        line-height: normal;
      }
      .PicsioIcon {
        margin: unset;
        margin-right: 4px;
      }
    }

    #removeCustomFieldSort {
      color: inherit;
      margin-left: 15px;
    }
  }

  &::after {
    content: '';
    bottom: 100%;
    left: 18px;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: $bgColor;
    border-width: 7px;
  }

  &.drop-active {
    visibility: visible;
    opacity: 1;
  }

  &.drop-active-left {
    &::after {
      left: auto;
      right: 18px;
    }
  }

  &.drop-active-left-filters {
    &::after {
      left: auto;
      right: 12px;
    }
  }

  &Item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;

    display: block;
    text-decoration: none;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    padding: 0 20px 0 50px;

    @include media('>=desktop') {
      &:hover {
        cursor: pointer;
        background: $brandColor;
        color: $bgColor;
      }
    }

    &.active {
      background: #131313;
      color: $brandColor;
      cursor: default;
    }

    &.listActive {
      color: $brandColor;
      background: transparent;
    }
  }

  // Cursor pointer for sort items
  &SortItem {
    display: flex;

    .toolbarDropdownItemText {
      flex: 1;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .arrows {
      width: 17px;
      opacity: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 30px;
    }

    .arrow-desc,
    .arrow-asc {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .arrow-desc {
      &:before {
        content: ' ';
        width: 0;
        height: 0;
        display: block;
        border-color: transparent transparent currentColor transparent;
        border-style: solid;
        border-width: 0 4px 8px 4px;
      }
    }

    .arrow-asc {
      &:before {
        content: ' ';
        width: 0;
        height: 0;
        display: block;
        border-color: currentColor transparent transparent transparent;
        border-style: solid;
        border-width: 8px 4px 0 4px;
      }
    }

    &.active {
      cursor: pointer;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover,
    &.active {
      .arrows,
      .sortDirections {
        opacity: 1;
      }

      .arrow-disabled {
        opacity: 0.25;
      }
    }
  }

  &ItemIcon {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 40px;
    //line-height: 40px;
    text-align: center;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Header {
    -webkit-font-smoothing: antialiased;
    display: none;
    justify-content: space-between;
    padding: 0 0 0 15px;
    align-items: center;
  }

  &Separator {
    margin: 7.5px 13px;
    height: 1px;
    display: block;
    //background: #323232;
    background: var(--separate-border-color);
  }
}

.toolbarPreviewRightMobile {
  display: none;
}

@include media('<tablet') {
  .mobileHidden {
    display: none;
  }

  .mobileNotVisible {
    position: absolute;
    top: -999px;
    left: -999px;
  }

  .toolbarPreviewRightMobile {
    width: 50px;
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 4;
    display: block;
    background: none;
  }

  .transparentToolbarButton {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
    margin-bottom: 1px;

    &:hover {
      color: #fff !important;
    }
  }

  .zoomInButton,
  .zoomOutButton {
    &::before {
      content: '';
      width: 20px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -10px;
    }
  }

  .zoomInButton {
    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
    }
  }

  #button-fitsize {
    .toolbarPreviewLeft & {
      display: none;
    }
  }

  .originalSizeImg,
  .fitSizeImg {
    display: flex;
    font-size: 16px;
  }
}

@include media('>=tablet') {
  .moreMobileHidden {
    display: none;
  }
}

@include media('<desktop') {
  .tabletHidden {
    display: none;
  }

  .tabletNotVisible {
    position: absolute;
    top: -999px;
    left: -999px;
  }
}

@include media('>=desktop') {
  .desktopHidden {
    display: none;
  }
}

.asc,
.desc {
  width: 5px;
  height: 5px;
  position: absolute;
  right: 12px;
  top: 17px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
}

.desc {
  &::after {
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    border-top: 5px solid currentColor;
  }
}

.asc {
  top: 28px;

  &::after {
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    border-bottom: 5px solid currentColor;
  }
}

.toolbarButtonDropdownNotClickable {
  .toolbarDropdownItem {
    pointer-events: none;
  }

  @include media('>=desktop') {
    .toolbarDropdownItem {
      display: block;
      white-space: normal;
      height: auto;
      line-height: 20px;
      padding: 10px 20px 10px 50px;
    }

    .toolbarDropdown {
      min-width: 300px;
    }
  }
}

.toolbarDropdownWrapper {
  display: none;

  @include media('<desktop') {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0;
  }
}

.toolbarDropdownWrapper,
.mobileAdditionalPanel-body {
  @include media('<desktop') {
    .toolbarDropdown {
      width: 100%;
      height: 100%;
      position: static;
      transform: none !important;
      overflow: auto;
      top: 0;
      padding-top: 50px;

      &Header {
        display: flex;
        height: 50px;
        line-height: 50px;
        background-color: var(--background-contrast-color);
        color: var(--title-color);
        font-size: 14px;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 0;
      }

      .toolbarName {
        text-align: center;
        padding-right: 50px;
        flex: 1;
      }

      &Item,
      &SortItem {
        height: 50px;
        min-height: 50px;
        line-height: 50px;
        border-bottom: 1px solid var(--separate-border-color);
        font-size: 18px;
        font-weight: 300;

        &Icon {
          height: inherit;
          line-height: inherit;
        }
      }

      &Separator {
        display: none;
      }
    }
  }

  .toolbarButton:hover &,
  .toolbarButton.hover &,
  .toolbarButton.drop-active &,
  .jobsStatusOpener:hover &,
  .jobsStatusOpener.hover &,
  .jobsStatusOpener.drop-active & {
    display: flex;
  }
}

// For adding '...' to proofing title
.toolbarCatalogProofing {
  .toolbarCatalogTop {
    .toolbarGroup-first {
      min-width: 0;
    }
  }

  @media screen and (max-width: $mediaDevice) {
    .toolbarButton.collections {
      // position: absolute;
      // z-index: 1;

      // &::before {
      //   background-color: #333;
      // }

      &.active {
        &::before {
          background-color: #242424;
        }
      }
    }
  }
}

// Upload Button
.buttonFileUpload {
  overflow: hidden;

  [type='file'] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.jobsStatusOpener {
  height: 50px;
  min-width: 50px;
  position: relative;
  color: inherit;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    .jobs-status-spinner {
      opacity: 1;
    }
  }

  .toolbarDropdownWrapper {
    cursor: default;
  }
}

.jobs-status-spinner {
  display: inline-flex;
  width: 50px;
  height: 50px;
  background: url(https://assets.pics.io/img/kebab-spinner.svg) no-repeat 50%
    50%;
  background-size: 30px 30px;
  opacity: 0.8;
  margin-left: -8px;
}

.jobs-status-wrapper {
  display: flex;
}

.jobs-status {
  line-height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }

  @media only screen and (max-width: 1320px) {
    span {
      display: none;
    }
  }
}

.listItemsBlock {
  display: flex;
  align-items: center;

  &:hover {
    background: #ffc000;
  }

  &.active {
    background: #131313;
  }
}

.jobs-status-title {
  text-align: center;
  padding: 10px;
  font-weight: 300;
  font-size: 13px;
  white-space: nowrap;

  @include media('<desktop') {
    font-size: 16px;
  }
}

.PicsioButton.requestDemoButton {
  width: auto;
  align-self: center;
  color: $brandColor;

  .toolbar & {
    margin: 0 10px;
  }

  .PicsioIcon {
    display: block;

    svg {
      fill: currentColor;
    }
  }

  &.withHover {
    &:hover {
      color: $brandColorHover;
    }
  }
}

.liveSupportButton {
  color: $brandColor;

  &:hover {
    color: $brandColorHover;
  }
}

// Theming
.picsioThemeLight {
  .toolbar {
    background: #f4f4f4;
    color: #666;

    &Button {
      &:hover {
        color: #000;
      }

      &.color {
        color: #000;
        background: $brandColor;
      }

      &.active {
        color: #000;

        &::before {
          background: #fff;
        }
      }
    }

    .drop-active {
      color: #000;
    }

    .multipagePaging {
      b {
        color: #000;
      }
    }

    .setAsCurrentRevision {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 5%;
        right: 0;
        bottom: 0;
        height: 1px;
        background: #f4f4f4;
        width: 90%;
      }

      &:hover {
        color: var(--primary-contrast-color);
        background: var(--primary-main-color);

        &::before {
          height: 0px;
        }
      }
    }
  }

  .toolbarCatalogLeft {
    .icon-toolbarDownload {
      &::after {
        background: #eee;
      }
    }

    @include media('<desktop') {
      background: #fff;
      border-color: #f4f4f4;

      .toolbarButton {
        color: #666;
        // &::before {
        // }

        &.active {
          color: #000;

          &::before {
            border-color: #f4f4f4 #fff #f4f4f4 transparent;
            background: #fff;
          }
        }
      }
    }
  }

  .toolbarDropdown {
    background: #fff;
    color: #666;
    box-shadow: $shadowModalLightTheme;

    &::after {
      border-bottom-color: #fff;
    }

    &Item {
      color: inherit;

      &.active {
        color: #000;
        background: #f1f1f1;

        .toolbarDropdownItemIcon {
          color: $brandColorLightTheme;
        }
      }

      &:hover {
        background: $brandColorLightTheme;
        color: #fff;

        .toolbarDropdownItemIcon {
          color: inherit;
        }
      }
    }

    // &Separator {
    //   //background: $silver;
    // }
  }

  .mobileAdditionalPanel-body,
  .toolbarDropdownWrapper {
    @include media('<tablet') {
      background-color: #fff;

      .toolbarDropdown {
        box-shadow: none;

        // &Item {
        // 	border-bottom-color: $silver;
        // }
      }

      &.isActive {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .jobs-status-spinner {
    background-image: url(https://assets.pics.io/img/kebab-spinner-white.svg);
  }

  .PicsioButton.requestDemoButton {
    color: $brandColorLightTheme;

    &.withHover {
      &:hover {
        color: $brandColorLightThemeHover;
      }
    }
  }

  .liveSupportButton {
    color: $brandColorLightTheme;

    &:hover {
      color: $brandColorLightThemeHover;
    }
  }

  .proofingContacts {
    &__toggler,
    &__content {
      color: #666;
    }

    &__toggler {
      &--openned,
      &:hover {
        color: #000;
      }
    }

    &Popper {
      background: #fff;
      color: #666;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.13);
    }
  }
}

.videoControlsDrawer {
  display: flex;
  flex-flow: column;
  background: var(--background-menu-color);

  .picsioThemeDark & {
    color: #ccc;
  }

  .picsioThemeLight & {
    color: #999;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 16px 16px 12px 12px;
    background-color: var(--background-contrast-color);

    h4 {
      flex: 1 auto;
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: normal;
      line-height: 24px;
    }
  }

  .#{$picsioMenuListClassName} {
    height: 100%;
    max-height: 100%;
    box-shadow: none;
    overflow-y: auto;
  }

  .#{$picsioMenuItemClassName} {
    padding: 12px;
    color: inherit;

    .picsioThemeDark & {
      border-bottom: 1px solid #323232;
    }

    .picsioThemeLight & {
      border-bottom: 1px solid rgba(#000, 0.15);
    }

    &__text {
      margin-left: 18px;
    }
  }
}

.proofingContacts {
  &__wrapper {
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 15px;
    }
  }

  &__toggler,
  &__content {
    color: #ccc;
    font-size: 14px;
    line-height: 20px;
  }

  &__toggler {
    @include button-reset;
    transition: color 0.2s ease;

    &--openned,
    &:hover {
      color: #fff;
    }
  }

  &__row {
    word-break: break-all;

    &--social,
    &--contacts {
      margin: 1em 0;
    }

    &--contacts {
      white-space: pre-wrap;
    }
  }

  &Popper {
    width: 300px;
    padding: 34px 47px 35px 37px;
    background: #242424;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);

    &__close {
      width: 10px;
      height: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;

      button {
        font-size: 8px;
      }
    }

    .#{$picsioPopperClassName} {
      &__arrow {
        width: 10px;
        height: 10px;
        box-shadow: none;
      }

      &__content {
        background-color: transparent;
      }
    }
  }
}
