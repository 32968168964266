@import 'cssVariables';

.SearchableDropdown {
  &__body {
    flex: 1 1 0;
    position: relative;
    width: 100%;
  }

  &__head {
    font-size: 14px;
    line-height: 1.1;
    padding: 10px 5px;
    color: #999;
    border-bottom: 1px solid #1d1d1d;

    &__item {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #fff;
        .SearchableDropdown__head__triangle {
          border-top-color: #fff;
        }
      }
    }

    &__triangle {
      margin-left: 25px;
      font-size: 6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 3px 0px 3px;
      border-color: #999 transparent transparent transparent;
    }
  }

  &__menu {
    position: relative;
    padding: 10px 0;
    box-shadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.8);
    background: #262525;
    max-height: 265px;
    width: 267px;
    overflow: auto;
    backface-visibility: hidden;
  }

  &__search {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #262525;
    padding: 0px 15px 15px 15px;
    font-size: 14px;
    line-height: 1.1;
  }

  &__noresults {
    text-align: center;
    padding: 15px 20px 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #ccc;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.1;
      padding: 9px 35px 9px 35px;
      color: #ccc;
      cursor: pointer;
      position: relative;

      &:hover {
        background: $brandColor;
        color: #000;

        & .SearchableDropdown__list__item__iconOk {
          fill: #000;
        }
      }

      &__avatar {
        margin-right: 8px;
      }

      &__iconOk {
        fill: #ccc;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .searchUsers {
      padding: 0 15px 0 15px;
    }
  }

  &__checkedItems {
    padding: 10px 0;

    &__tag {
      border-radius: 10px;
      padding: 0 6px 0 5px;
    }
  }

  .picsioThemeLight & {
    &__head {
      color: #666;
      border-bottom-color: #d9d9d9;

      &__item {
        &:hover {
          color: #666;

          .SearchableDropdown__head__triangle {
            border-top-color: #666;
          }
        }
      }
    }

    &__search {
      background: #fff;
    }

    &__menu {
      background: #fff;
      box-shadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.15);
    }

    &__noresults {
      color: #666;
    }

    &__list {
      &__item {
        color: #666;
        &:hover {
          background: $brandColorLightTheme;
          color: #fff;

          & .SearchableDropdown__list__item__iconOk {
            fill: #fff;
          }
        }
      }
    }
  }
}
