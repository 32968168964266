.wrapperDropboxChooserDialog {
  &.wrapperDialog {
    .simpleDialogBox {
      width: 80vw;
      max-width: 1024px;
    }

    .simpleDialogTitle {
      font-weight: normal;
    }

    .simpleDialogContent {
      padding: 0;
      margin-top: 16px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #242424;

      .picsioThemeLight & {
        border-bottom: 1px solid #dfdede;
      }
    }

    .simpleDialogDescription {
      padding: 0 24px;
      padding-bottom: 24px;

      &__text {
        margin-bottom: 20px;
      }
    }

    .simpleDialogContentInner {
      padding: 0;

      .dropboxChooserPlaceholder {
        position: relative;
        height: calc(50vh + 70px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
