// @TODO: move to UI
:root,
html.picsioThemeDark {
  --input-field-bg-rgba: 48, 48, 48;
}

html.picsioThemeLight {
  --input-field-bg-rgba: 244, 244, 244;
}

.pageWrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $screenBgDark;
  &:empty {
    display: none;
  }
}

.page {
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #ccc;

  .pageContent {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    .pageInnerContent {
      overflow: auto;
      flex: 1 1 auto;
      //margin-bottom: 15px;
      box-sizing: border-box;
      position: relative; // added for errorPlaceholder position
      padding-top: 50px;

      @include media('<desktop') {
        padding: 30px 15px 0;
      }
    }
  }
}

.pageContainer {
  width: 100%;
  max-width: 1016px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.pageWidthContainer {
  max-width: 500px;
}

.pageItemOktaLink {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;

    & a {
      text-decoration: none;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__placeholder {
    color: $darkgray;
    font-size: 14px;
  }
}

.pageItemOktaMobileCheckbox {
  padding: 5px 0 15px;
}

.pageItem {
  padding: 40px 0 10px;
  line-height: 1.2;

  &:first-child {
    padding-top: 0;
  }

  &~.pageItem {
    border-top: 1px solid var(--separate-border-color);
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .UICheckbox + .PicsioIcon {
    margin-left: 9px;
  }

  &.bottomGap {
    padding-bottom: 30px;
  }
}

.pageItemBlock {
  margin-bottom: 40px;
  max-width: 384px;

  &.small {
    margin-bottom: 24px;
  }
}

.pageItemRow {
  padding-bottom: 15px;
}

.pageItemTitle {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 32px;
  color: var(--title-color);
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;

  .badge {
    font-size: 10px;
    font-weight: 400;
  }

  span:first-child {
    margin-right: 8px;
  }

  &__iconContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.picsioThemeLight {
  .pageItemTitle {

    path {
      fill: $brandColorLightTheme;
    }
  }
}

.pageItemTitleSmall {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 15px;
  color: #ccc;
}

.pageItemTitleColor {
  color: var(--title-color);
}

.pageItemButtons {
  margin: 0 0 16px;
}

.pageItemBlockHelp {
  margin: 26px 0 16px;
}

.pageItemCheckbox {
  margin-bottom: 20px;
  line-height: 17px;

  .UICheckbox {
    align-items: flex-start;
    vertical-align: middle;

    &__checkbox {
      flex: 0 0 15px;
    }

    &__label {
      white-space: normal;
    }

    & + .upgradePlan {
      margin-left: 10px;
    }
  }
}

.pageItemCheckboxDescription {
  margin-top: 5px;
}

.pageItemSelect {
  margin-bottom: 20px;
}

.fieldCopyTo {
  display: flex;
  &Url {
    flex: 1;
    margin-bottom: 0;
    input {
      color: $gray;
      @include media('<desktop') {
        font-size: 16px;
      }
    }
  }
  &Btn {
    position: relative;
    min-width: 30px !important;
    width: 30px;

    &-text {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    @include media('<desktop') {
      width: 50px;
      font-size: 18px;

      &-text {
        width: auto;
      }
    }

    .svg-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &Holder {
    flex: 1;
    position: relative;

    &::after {
      content: '';
      // height: 100%;
      width: 50px;
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 0;
      background-image: linear-gradient(
        to right,
        transparent 0%,
        rgba(var(--input-field-bg-rgba), 1) 30%,
        rgba(var(--input-field-bg-rgba), 1) 100%
      );
    }
  }

  .isFocused &Holder {
    &::after {
      display: none;
    }

    .fieldCopyToSettings {
      display: none;
    }
  }

  .PicsioButton.fieldCopyToSettings {
    height: 100%;
    width: 30px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 !important;
    background-color: transparent;
    z-index: 1;

    &:hover {
      background-color: transparent;
    }
  }
}

.checkIcon {
  path {
    fill: #B6FA18;
  }
}

.picsioThemeLight {
  .pageWrapper {
    background-color: #fff;
  }

  .page {
    color: #666;
  }

  // .pageItem {
  // 	&:not(:first-child) {
  // 		border-color: #ebebeb;
  // 	}
  // }

  // .pageItemTitle {
  // 	color: #000;
  // }

  .pageItemTitleSmall {
    color: #666;
  }

  // .pageItemTitleColor {
  // 	color: #000;
  // }

  .fieldCopyTo {
    &Url input {
      color: $gray;
    }
    &Btn.picsioLinkForShare {
      color: $gray;
    }
  }
  .fieldCopyTo {
    &Url input {
      color: $gray;
    }
    &Btn.picsioLinkForShare {
      color: $gray;
    }
  }

  .pageItemOktaLink {
    &__placeholder {
      color: #AAAAAA;
    }
  }

  .checkIcon {
    path {
      fill: #00BE4C;
    }
  }
}

.divider {
  border: 1px solid var(--separate-border-color);

  &.mb-14 {
    margin-bottom: 14px;
  }
}
