.confirmComponent {
  max-height: 100%;
	font-weight: 300;
	color: #ccc;
	overflow: auto;

	.pageContent {
		padding: 50px 0;
		box-sizing: border-box;
		overflow: auto;
	}

	.pageContainer {
		max-width: 630px;
		padding: 0 15px;
	}

	&Body {
		padding: 20px 0;
		font-size: 14px;
		line-height: 17px;
	}

	&Form {
		margin-top: 25px;
		max-width: 500px;

		.row {
			margin-bottom: 15px;
			display: flex;
			align-items: center;

			.error {
				padding-left: 10px;
				color: #ff0000;
			}
		}

		input {
			width: 250px;
		}

		.UICheckbox {
			margin: 5px 0;
			width: 250px;
		}

		.removeBtn {
			min-width: 90px;
			border: 1px solid #7a1320;
		}
	}

	input.has-error {
		border: 1px solid #7a1320 !important;
	}
	.errorBox {
		color: #c00;
	}
}

.picsioThemeLight {
	.confirmComponent {
		color: #666;

		&Header {
			color: #ccc;
		}
	}
}
