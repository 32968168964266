.bounding-box-container {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #B6FA18;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }

  &.active{
    outline: 6px solid #B6FA18;
  }
  .close {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -14px;
    left: -11px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    display: none;
    cursor: pointer;

    &.active {
      display: flex;
    }
  }
}

.title {
  background: #000000;
  color: #fff;
  position: absolute;
  padding: 7px 11px;
  bottom: 104%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 0;
  height: 16px;
  .editIcon {
    margin-left: 5px;
  }
}


.picsioThemeLight {
  .title {
    background: #F4F4F4;
    color: #666;
  }
}
