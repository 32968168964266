.previewWarning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  z-index: 9999;
  background-color: #000;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: rgba(255, 107, 0, 0.20);
    padding: 12px 15px;
  }

  &__warningIcon {
    font-size: 24px;
    min-width: 24px;
    path {
      fill: #FF6B00;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 4px 10px;
    color: #FF6B00;
    font-family: 'Fira Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__closeIcon {
   .PicsioIcon svg {
      font-size: 17px;
    }

    &:hover {
      .PicsioIcon svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
