@import './colors';
@import '../mixins';

$themeColors: (
  'light': (
    'primary': (
      'contrast': $white,
      'light': $brandColorLightThemeHover,
      'main': $brandColorLightTheme,
    ),
    'secondary': (
      'contrast': $black,
      'light': $lightgray,
      'main': $gray,
      'dark': $darkgray,
      'lightblack': $lightblack,
    ),
    'title': $black,
    'text': $textColorLight,
    'button': (
      'primary': $buttonColorLight,
      'secondary': $darkgray,
    ),
    'icon': (
      'primary': #999,
    ),
    'switch-background': (
      'checked': hsla(0deg, 0%, 80%, 0.15),
    ),
    'button-background': (
      'primary': (
        linear-gradient(0deg, rgba(47, 101, 250, 0.48913) 0%, rgba(47, 101, 250, 5e-5) 100%),
        #3b94fb,
      ),
      'secondary': (
        linear-gradient(0deg, rgba(50, 50, 50, 0.1) 0%, rgba(51, 51, 51, 1e-5) 100%),
        #fcfcfc,
      ),
    ),
    'background': (
      'contrast': $white,
      'light': #f9f9f9,
      'dark': #f4f4f4,
      'details-edit': #f4f4f4,
    ),
    'border': (
      'checkbox': #cccccc,
    ),
    'chart': (
      'axis-line': $chartAxisLineColor,
    ),
  ),
  'dark': (
    'primary': (
      'contrast': $black,
      'light': $brandColorHover,
      'main': $brandColor,
    ),
    'secondary': (
      'contrast': $white,
      'light': $lightgray,
      'main': $gray,
      'dark': $darkgray,
    ),
    'title': $white,
    'text': $textColorDark,
    'icon': (
      'primary': #999,
    ),
    'switch-background': (
      'checked': hsla(0deg, 0%, 80%, 0.13),
    ),
    'button': (
      'primary': $buttonColorDark,
      'secondary': $darkgray,
    ),
    'button-background': (
      'primary': (
        linear-gradient(to top, rgba(255, 153, 0, 0.2) 0%, rgba(255, 153, 0, 2e-5) 100%),
        #ffcc00,
      ),
      'secondary': (
        linear-gradient(0deg, rgba(50, 50, 50, 0.3) 0%, rgba(51, 51, 51, 3e-5) 97.97%),
        #333333,
      ),
    ),
    'background': (
      'contrast': #242424,
      'light': #1f1f1f,
      'dark': $black,
      'details-edit': #1e1e1e,
    ),
    'border': (
      'checkbox': #ebebeb,
    ),
    'chart': (
      'axis-line': $chartAxisLineDarkColor,
    ),
  ),
);

@mixin useTheme($theme) {
  $currentTheme: map-get($themeColors, $theme);

  @each $variable, $color in $currentTheme {
    @if type-of($color) == 'map' {
      @each $sub-variable, $sub-color in $color {
        $rgb-color: convertHexToRgb($sub-color);

        --#{$variable}-#{$sub-variable}-color: #{$sub-color};

        @if $rgb-color {
          --#{$variable}-#{$sub-variable}-color-rgb: #{$rgb-color};
        }
      }
    } @else {
      $rgb-color: convertHexToRgb($color);

      --#{$variable}-color: #{$color};

      @if $rgb-color {
        --#{$variable}-color-rgb: #{$rgb-color};
      }
    }
  }
}

video::cue {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;

  @media screen and (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
  }
}
