@import 'cssVariables';

.restrictSettings {
  color: #999;

  .datepickerField {
    margin-bottom: 25px;
  }

  .PicsioInputLabel {
    font-size: 11px;
    color: #666;
    margin-bottom: 5px;
  }

  .PicsioInput {
    margin-bottom: 15px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .PicsioInputHelperText {
    margin-bottom: 8px;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.tiles {
  display: flex;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 8px;
  z-index: 3;

  >div {
    margin-right: 8px;
  }

  .tileResticted {
    color: $warning;
  }

  .tileArchived {
    .icon {
      width: 14px;
      height: 14px;
      color: var(--primary-main-color);
      opacity: 0.9;
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }
}


.restrictedAsset {
  color: $warning;
  font-size: 14px;
  padding: 0 15px;
}
