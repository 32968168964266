.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
		* {
			fill: currentColor;
		}
}

.icon-myTeam {
	width: 1.125em;
}

.icon-files {
	width: 1.03125em;
}

.icon-clearEye {
	width: 1.1875em;
}

.icon-users {
	width: 1.34375em;
}

.icon-number {
	width: 1.125em;
}

.icon-legal {
	width: 1.1875em;
}

.icon-arrowRight {
	width: 1.15625em;
}

.icon-downloadList {
	width: 1.1875em;
}

.icon-content {
	width: 1.21875em;
}

.icon-billInvoice {
	width: 1.5em;
}

// myTeam: '0 0 36 32',
// exportCsv: '0 0 28 32',
// flaggedFlag: '0 0 28 32',
// rejectedFlag: '0 0 28 32',
// unflaggedFlag: '0 0 28 32',
// files: '0 0 33 32',
// clearEye: '0 0 35 32',
// users: '0 0 43 32',
// number: '0 0 36 32',
// kebab: '0 0 26 32',
// legal: '0 0 40 32',
// import: '0 0 24 32',
// export: '0 0 24 32',
// arrowRight: '0 0 37 32',
// downloadList: '0 0 38 32',
// content: '0 0 39 32',
// hourglass: '0 0 23 32',
// hash: '0 0 28 32',
// attention: '0 0 5 32',
// billInvoice: '0 0 48 32',