.Breadcrumbs {
  .breadcrumb {
    font-family: 'Fira Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ccc;
    cursor: pointer;

    :global(.picsioThemeLight) & {
      color: #999;
    }

    &.active {
      pointer-events: none;
      font-weight: 700;
      color: #fff;
      cursor: default;

      :global(.picsioThemeLight) & {
        color: #666;
      }
    }
  }
}
