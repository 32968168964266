.TranscriptItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 250ms ease;

  &__content {
    display: flex;
    align-items: center;
  }

  & #btnEditSubtitle {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
  }

  &__inputEdit {
    width: 100%;
  }

  &.isActive,
  &:hover {
    background-color: #303030;

    .picsioThemeLight & {
      background-color: rgba(48, 48, 48, 0.16);
    }
  }

  &:hover {
    & #btnEditSubtitle {
      opacity: 1;
      pointer-events: auto;
      background-color: #30303088;

      .picsioThemeLight & {
        background-color: rgba(48, 48, 48, 0.16);
      }
    }
  }
  &.isLoading {
    opacity: 0.5;
  }

  &.isLoading::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: #30303088;

      .picsioThemeLight & {
        background-color: rgba(48, 48, 48, 0.16);
      }
  }

  &__time {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 2px 6px;
    margin-right: 16px;
    color: rgba(255, 192, 0, 1);
    background: rgba(255, 192, 0, 0.25);
    border-radius: 2px;

    .picsioThemeLight & {
      color: rgba(59, 148, 251, 1);
      background: rgba(59, 148, 251, 0.25);
    }
  }

  &__text {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #ffffff;

    .picsioThemeLight & {
      color: #333;
    }

    & em {
      font-style: normal;
      background: rgba(255, 192, 0, 0.25);
      border-radius: 2px;

      .picsioThemeLight & {
        background: rgba(59, 148, 251, 0.25);
      }
    }
  }
}
