.subtitle-container {
  position: relative;

  .tooltip {
    position: absolute;
    z-index: 99;
    top: -45%;
    left: 50%;
    translate: -50% 0;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background: var(--primary-main-color);
    color: var(--primary-contrast-color);
    border-radius: 2px;
    padding: 4px 8px;
    width: max-content;
    cursor: pointer;
  }

  .tooltip:before {
    content: '';
    position: absolute;
    z-index: 99;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid var(--primary-main-color);
    top: 100%;
    left: 50%;
    translate: -50% 0;
  }
}
