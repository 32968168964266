.tabs {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 13px;
    right: 13px;
    height: 1px;
    background-color: var(--background-contrast-color);
    z-index: -1;
  }
}

.tab {
  padding: 13px 12px 18px;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  color: var(--secondary-dark-color);
  transition: all 0.3s;
  font-size: 18px;
  line-height: 24px;

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 13px;
    right: 13px;
    height: 3px;
    transition: background-color 0.3s;
  }
}

.active {
  color: var(--primary-main-color);

  &::before {
    background-color: var(--primary-main-color);
  }
}

.buttonBulkMedia {
  padding: 13px 12px 18px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-grow: 2;
}
