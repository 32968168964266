.wrapperPageMyAccount {
  .pageMyAccount {
    .customSelect__select {
      option[disabled] {
        display: none;
      }
    }

    .pageTabsContentSettings {
      min-height: 100%;
      overflow-y: auto;
    }

    .pageTabsContentAccount {
      .upgradePlanContainer {
        margin-bottom: 24px;
      }
    }

    .pageTabsContentAccount__label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    .pageTabsContentNotifications {
      height: 100%;

      .UICheckbox + .tagList {
        margin-top: 10px;
      }
    }
  }
}
