/*************************************************************************/
/******************************** DEVICES ********************************/
/*************************************************************************/
$bgBlackMobile: rgba(0,0,0,0.9);

$mediaDevice: 1023px;
$mediaPhone: 480px;

$highdensity: "only screen and (-webkit-min-device-pixel-ratio: 1.5)",
							"only screen and (min--moz-device-pixel-ratio: 1.5)",
							"only screen and (-o-min-device-pixel-ratio: 3/2)",
							"only screen and (min-device-pixel-ratio: 1.5)";
$mobileSmall: "only screen and (max-width: 480px)";
$mobile:      "only screen and (max-width: 767px)";
$moreMobile:  "only screen and (min-width: 768px)";
$tablet:      "only screen and (min-width: 768px) and (max-width: 1023px)";
$desktop:     "only screen and (min-width: 1024px)";
$lessDesktop: "only screen and (max-width: 1023px)";
$widescreen:  "only screen and (min-width: 1230px)";
$xwidescreen: "only screen and (min-width: 1360px)";


@mixin mobileAnimationTranslateY_Hide() {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: -webkit-transform 0.2s linear;
	-moz-transition: -moz-transform 0.2s linear;
	transition: transform 0.2s linear;
}
@mixin mobileAnimationTranslateY_Show() {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	transform: translateY(0);
}
@mixin mobileAnimationTranslateX_Hide() {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: -webkit-transform 0.1s linear;
	-moz-transition: -moz-transform 0.1s linear;
	transition: transform 0.1s linear;
}
@mixin mobileAnimationTranslateX_Show() {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	transform: translateX(0);
}

@mixin mobileAnimationTranslateXleft_Hide() {
	transform: translateX(calc(-100% - 50px));
	transition: transform 0.1s linear;
}
@mixin mobileAnimationTranslateXleft_Show() {
	transform: translateX(50px);
}

@media screen and (max-width: $mediaDevice) {
	* {
		box-sizing: border-box;

		&::before,
		&::after {
			box-sizing: inherit;
		}
	}

	.wrapperPicsioApp {
		.appMain {
			height: 100%;
		}
	}
}

// .mobile {
// 	.appCatalog {
// 		.innerCatalog {
// 			overflow: hidden;
// 		}
// 		.mobileScroll{
// 			height: 100%;
// 			overflow: hidden;
// 		}
// 	}
// }


@include media('<desktop') {
	.wrapperPicsioApp {
		.appMain {
			padding-top: 0;
			height: 100%;
			.content {
				margin: 0;
			}
		}

		.appCatalog {
			padding-top: 50px;
		}

		// style Tree item
		.nameFolder.nameFolder {
			height: 42px;
			padding-top: 9px;
			padding-bottom: 9px;

			.arrowFolder {
				top: 18px;
				padding: 15px;
				margin: -15px;
			}

			.btnsManageCollection,
			.btnsEditCollection {
				> .moreBtns,
				.treeButtonSmall,
				.treeButtonSmall .btnCollection {
					height: 42px;
					width: 32px;
					line-height: 42px;
				}

				.moreBtns .treeButtonSmall .btnCollection {
					height: 42px;
				}

        > .btnCollection {
          height: 42px;
        }
			}
			&.editModeFolder {
				.btnCollection {
					height: 42px;
					width: 32px;
					line-height: 42px;
				}

				.collectionTextValue {
					width: calc(100% - 90px);
				}
			}
		}
	}

	.wrapperTiles {
		#tiles {
			.tile {
				&:nth-last-child(-n+2) {
					flex: auto;
				}
				&.longImg {
					.containerMediaFile {
						.innerContainerMediaFile {
							img {
								max-height: 150px;
							}
						}
					}
				}

				.tileHint,
				.tileFlag,
				.tileStars,
				.botCatalogItemToolbar {
					display: none;
					opacity: 0;
				}

				.catalogItemCheckbox {
					left: 0;
					top: 0;
					opacity: 1;
					padding: 8px;
					height: auto;
					width: auto;

					span {
						margin: 0;
						width: 15px;
						height: 15px;
					}
				}
			}
			&.catalogView_list {
				.tile {
					.tileHint,
					.tileFlag,
					.botCatalogItemToolbar {
						display: block;
						opacity: 1;
					}

					&.selectedTile {
						.catalogItemCheckbox {
							span {
								&::after {
									border: 1px solid #fff;
								}
							}
						}
					}

					.catalogItemCheckbox {
						left: 100px;
					}

					.descriptionCatalogItem {
						left: 108px !important;
						top: 32px !important;
						font-size: 11px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: calc(100% - 120px);
					}

					&.showStars {
						.tileStars {
							display: block;
							opacity: 1;
						}

						.descriptionCatalogItem {
							top: 54px !important;
						}
					}

					.tileStars {
						left: 108px;
						right: auto;
						top: 32px;
						font-size: 20px;

						ul {
							li {
								width: 20px;
							}
						}
					}

					.botCatalogItemToolbar {
						.moreTagsCatalogItem {
							border-left: 0;
						}

						.moreTagsCatalogItem {
							.dropTagsCatalogItem {
								right: 0;
								left: auto;
								z-index: 1;
							}
						}
					}

					.listTagsCatalogItem {
						background: none;
						padding-top: 3px;
						margin-left: 4px;
						max-width: calc(100% - 30px);

						.tagCatalogItem {
							border-radius: 2px;
							background-color: #242424;
							color: #fff;
							margin-right: 4px;
							position: relative;
							line-height: 22px;
							height: 22px;
							font-weight: 300;
							border: 0;
							padding: 0 4px;

							span {
								line-height: 22px;
							}

							&::before {
								display: block;
								width: 20px;
								height: 7px;
								position: absolute;
								left: 0;
								top: -3px;
								background-color: #242424;
								content: '';
								z-index: 1;
								border-radius: 3px;
							}
						}
					}

					.tileFlag {
						right: 0;
						top: 30px;
						&::after {
							font-size: 14px;
						}
					}
					.tileHint {
						ul li .tileName {
							max-width: calc(100% - 205px) !important;
							top: 5px !important;
							font-size: 18px;
							line-height: 20px;
							left: 133px;

							a {
								font-weight: 300;
							}

							.titleCatalogItem {
								font-weight: 400;
								font-family: inherit;
							}
						}
						ul + ul {
							position: absolute;
							right: -2px;
							bottom: 0;
							width: 100%;
							height: 100%;
							background: #333;
							z-index: 7;
							@include mobileAnimationTranslateX_Hide();
							li {
								width: 33%;
								height: 70px;
								&::before {
									font-size: 22px;
									height: 70px;
									line-height: 70px;
								}
								&:hover {
									background: none;
								}
							}
						}
					}
					.dateCatalogItem {
						font-size: 12px;
						line-height: 20px;
						top: 5px;
					}


					.botCatalogItemToolbar {
						left: 108px;
						bottom: 4px;
						width: calc(100% - 108px);
					}
					&.showMobileManageBtns {
						.tileHint {
							ul + ul {
								@include mobileAnimationTranslateX_Show();
							}
						}
					}
				}
			}
		}
	}

	.mobileBtnClose {
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		text-align: center;
		color: #858887;
		font-size: 24px;
		line-height: 42px;
	}

	.emptyCollection {
		.textEmptyCollection {
			div {
				font-size: 28px;
			}
			span {
				font-size: 14px;
				line-height: 1.4;
			}
		}
	}

	.introjs-helperLayer {
		.introjs-tooltip {
			.introjs-tooltiptext {
				font-size: 14px;
				line-height: 18px;
				padding: 20px;
				.titleIntroJs {
					font-size: 26px;
					line-height: 1;
					letter-spacing: -1px;
					padding: 0px 0 10px 0;
				}
				br {
					display: none;
				}
			}
		}
		&.GDIntroPopup {
			.introjs-tooltip {
				position: fixed;
				top: 145px;
				bottom: auto !important;
				left: 50%;
				margin: 0;
				min-width: 300px;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				transform: translateX(-50%);
				.introjs-arrow.top {
					left: 50%;
					margin-left: -9px;
				}
			}
		}
		&.helloIntroPopup {
			left: 10px !important;
			left: 50% !important;
			-webkit-transform: translateX(-150px);
			-moz-transform: translateX(-150px);
			transform: translateX(-150px);
			margin-top: -76px;
			.introjs-tooltip {
				max-width: calc(100% - 20px) !important;
				min-width: 300px !important;
				margin: 0 !important;
				left: 0 !important;
				top: 0 !important;
			}
		}
		&.collectionIntroPopup {
			.introjs-tooltip {
				min-width: 300px !important;
				margin: 0 !important;
				left: 10px !important;
				top: 60px !important;
				position: fixed;
				bottom: auto !important;
				max-width: calc(100% - 20px) !important;
				width: 100%;
				.introjs-arrow.top {
					left: 100%;
					margin-left: -140px;
				}
				.introjs-bullets {
					left: 0;
					height: 50px;
					ul {
						display: none;
					}
				}
			}
		}
	}
}

@include media('<tablet') {
	body #intercom-container .intercom-launcher-frame {
		bottom: 50px !important;
	}

	.wrapperTiles {
		#tiles {
			&.catalogView_list {
				.tile {
					height: 76px !important;
					margin-bottom: 0;

					.catalogItemCheckbox {
						left: 77px;
						padding-top: 5px;
					}

					.tileStars {
						left: 85px;
						top: 25px;
						font-size: 14px;

						ul {
							li {
								width: 16px;
							}
						}
					}

					.tileFlag {
						right: 0;
						top: 20px;
						&::after {
							font-size: 14px;
						}
					}
					.tileHint {
						ul li .tileName {
							max-width: calc(100% - 175px) !important;
							top: 3px !important;
							font-size: 14px;
							line-height: 20px;
							left: 105px;
						}
					}
					.dateCatalogItem {
						font-size: 12px;
						line-height: 20px;
						top: 3px;
					}
					.descriptionCatalogItem {
						left: 85px !important;
						top: 28px !important;
						font-size: 11px;
					}

					&.showStars {
						.descriptionCatalogItem {
							left: 174px !important;
							top: 28px !important;
							max-width: calc(100% - 200px);
						}
					}
					.botCatalogItemToolbar {
						left: 85px;
						bottom: 4px;
						width: calc(100% - 85px);

						.listTagsCatalogItem {
							margin-left: 0;

							.tagCatalogItem {
								line-height: 20px;
								height: 20px;

								span {
									font-size: 11px;
								}
							}
						}

						.colorCatalogItem {
							position: absolute;
							top: -44px;
							left: -16px;
							height: 72px;
							width: 5px;
						}
					}

					.containerMediaFile {
						height: 72px !important;
						width: 72px !important;
					}
				}
			}
		}
	}

	// .wrapperKeywordsTree {
	// 	.listFolderTree {
	// 		> ul {
	// 			> li {
	// 				> .nameFolder {
	// 					.btnsManageCollection {
	// 						display: block;

	// 						&::after {
	// 							content: none;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

@include media('<desktop') {
	.picsioThemeLight {
		.wrapperPicsioApp {
			.appMain {
				.content {
					background-color: #fff;
				}
			}
		}

		.wrapperTiles {
			#tiles {
				&.catalogView_list {
					.tile {
						.tileHint {
							ul + ul {
								background: #fff;
								li {
									&:hover {
										color: #999;
									}
									&:active {
										color: $brandColorLightTheme;
									}
								}
							}
						}

						.listTagsCatalogItem {
							.tagCatalogItem {
								background-color: #aaa;

								&::before {
									background-color: #aaa;
								}
							}
						}

						&.selectedTile {
							.catalogItemCheckbox {
								span {
									&::after {
										background-color: $brandColorLightTheme;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
