@import 'cssVariables';

@mixin handlerActiveStyle {
  .picsioThemeLight & {
    span {
      background-color: #c2c2c2;
    }
  }

  .picsioThemeDark & {
    span {
      opacity: .66;
    }
  }
}

.detailsPanelEdit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 150%;
  background-color: var(--background-main-color);
  transition: .2s ease-in;
  z-index: -1;
  pointer-events: none;

  &--opened {
    left: 2px;
    z-index: 5;
    pointer-events: auto;
  }

  &--dragging {
    .detailsPanelEdit {
      &__field {
        &:hover {
          color: var(--secondary-dark-color);
        }
      }
    }
  }

  &__header .PicsioButton--icon {
    margin-top: 0 !important;
  }

  &__footer {
		padding: 11px 12px 29px 12px;
		background-color: inherit;
	}

  &__headerControls {
    display: grid;
    grid-template: 30px / 24px 1fr 24px;
    column-gap: 10px;
    align-items: center;
    margin-top: 10px;
    padding: 0 12px 10px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #444;

    h4 {
      font-weight: normal;
      line-height: 21px;
      font-size: 14px;
      color: var(--text-color);
      margin: 0;
    }
  }

  &__headerTitle {
    display: grid;
    grid-template: 48px / 1fr 26px;
    align-items: center;
    padding: 0 20px 0 18px;

    div {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--secondary-contrast-color);
    }

    .PicsioButton--icon {
      color: var(--text-color);
    }
  }

  &__list {
    min-height: 0;
    flex: 1 auto;
    overflow: auto;

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .detailsPanel__header,
  .detailsPanel__item {
    margin-bottom: 0;
  }

  &--dragging {
    .detailsPanelEdit__field:hover {
      .detailsPanelEdit__field__dnd {
        display: none;
      }
    }
  }

  &__field {
    display: grid;
    grid-template: 40px / 18px 28px 1fr 26px;
    align-items: center;
    position: relative;
    padding-right: 20px;
    margin-bottom: 3px;
    cursor: pointer;
    color: var(--text-color);

    .PicsioButton--icon {
      margin-top: 0 !important;
      color: var(--text-color);
    }

    &.root {
      background-color: #212121;
    }

    &:hover {
      color: var(--secondary-contrast-color);
      background-color: var(--background-details-edit-color);
      .detailsPanelEdit__field__dnd {
        display: block;
        cursor: grab;
      }
    }

    &--dragging {
      .detailsPanelEdit__field {
        color: var(--secondary-contrast-color);
        background-color: var(--secondary-button-bg-hover-color);

        &__dnd {
          display: block;
        }
      }
    }

    &__section {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        padding: 0;
        margin-left: 8px;
      }
    }

    &__dnd {
      display: none;
      path {
        fill: #666;
      }
    }
  }
}

.detailPanelEmpty {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100%;

  &__text {
    margin-top: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  &__icon {
    font-size: 40px;
    path {
      fill: #666;
    }
  }
}

.btnCollapseGroup {
  &::before {
    content: '';
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent var(--text-color);
    width: 0;
    height: 0;
    display: block;
    transform: rotate(90deg);
  }

  &.act {
    &::before {
      transform: rotate(0deg);
    }
  }
}

.picsioThemeLight {
  .detailsPanelEdit__field {
    &.root {
      background-color: var(--background-details-edit-color);
    }

    &:hover {
      background-color: #ebebeb;
    }
  }
}
