.TranscriptPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;

  .upgradePlan {
    margin-bottom: 10px;
  }

  .Placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px;

    &.notUpgrade {
      opacity: 0.5;
      pointer-events: none;
    }

    &__title {
      margin: 0;
      margin-bottom: 16px;
      font-family: 'Fira Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      color: #c1c3cc;
    }

    &__icon {
      path {
        fill: #454545;
      }
    }

    &__description {
      margin: 0;
      margin-top: 8px;
      margin-bottom: 24px;
      font-family: 'Fira Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #c1c3cc;
    }

    &__upgradePlanContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__helperLink {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      color: var(--primary-main-color);
      text-decoration: none;
    }

    &__helperText {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}
