.checkbox {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;

	> span {
		box-sizing: border-box;
		display: inline-block;
		width: 10px;
		height: 10px;
		margin: auto;
		background-color: rgba(0,0,0,.1);

		border: 1px solid rgba(255,255,255,0.50);
		box-shadow: 0 2px 4px rgba(0,0,0,0.5);
		position: relative;
	}

	&.isActive {
		> span {
			&::after {
				content: '';
				position: absolute;
				top: 1px;
				left: 1px;
				bottom: 1px;
				right: 1px;
				background-color: #fff;
			}
		}
	}
}

.picsioThemeLight {
	.checkbox {
		> span {
			background-color: rgba(0,0,0,.1);	
			border: 1px solid rgba(0, 0, 0, 0.4);
			box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
		}
	
		&.isActive {
			> span {
				&::after {
					background-color: #666;
				}
			}
		}
	}
}
