.header {
  border-bottom: 1px solid var(--skeleton-default-color);
  margin: 0 !important;
  padding-bottom: 15px;

  :global(.PicsioSelect) {
    width: auto;
  }
  :global(.PicsioTextField) {
    width: 100%;
    margin-right: 5px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.chipsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 7px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;

  :global(.PicsioTextField) {
    width: 100%;
  }
}

.subRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 12px;

  :global(.PicsioTextField) {
    width: 60px;
    text-align: right;

    :global(input) {
      text-align: right;
    }
  }
}

.titleRow {
  color: var(--secondary-main-color);
  margin-bottom: 10px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0 5px;
}

.title {
  width: 100%;
  flex: 1;
}

.count {
  width: 65px;
  text-align: right;
}

.number {
  box-sizing: border-box;
  padding-right: 12px;
  font-size: 14px;
}

.hr {
  border-color: var(--skeleton-default-color);
  margin: 20px 0;
}
