/******************************* breadCrumbs ******************************/
.wrapperBreadcrumbs {
	float: left;
	padding: 15px 0 15px 25px;
	max-width: calc(50% - 25px - 250px - 50px - 10px);
	overflow: hidden;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 25px;
		height: 100%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
		background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	@media screen and (max-width: $mediaDevice) {
		display: block !important;
    padding: 0 5px 11px 0;
		width: auto !important;
		max-width: 100% !important;
		&::after {
			display: none;
		}
		&::before {
			content: '';
			width: 20px !important;
			height: 100%;
			position: absolute;
			left: 50px;
			top: 0;
			//background: linear-gradient(to right, rgba(51, 51, 51, 1), rgba(51, 51, 51, 0)) !important;
			z-index: 1;
		}
	}

	.iconErrorBoundary {
		display: none;
	}
}

.breadCrumbs {
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	display: block;
	position: relative;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		line-height: 20px;
		float: right;
		li {
			@include toolbarTitle;
			margin: 0;
			padding: 0;
			display: inline-block;
			vertical-align: top;
			position: relative;
			&:not(:first-child)::before {
				content: '/';
				display: inline-block;
				padding: 0 5px;
				color: $dark-darkgray;
			}
			&:last-child,
			&.disabled {
				span {
					color: $lightgray;
					text-decoration: none;
					cursor: default;
					pointer-events: none;
				}
			}
			span {
				font-family: inherit;
				font-size: inherit;
				color: inherit;
				text-decoration: none;
				display: inline-block;
				cursor:pointer;

				&:hover {
					color: $brandColor;
					text-decoration: none;
				}
			}
			@include media('<tablet') {
				color: $gray;
			}
		}
	}

	@media screen and (max-width: $mediaDevice) {
		display: block;
		padding: 15px 0 0 15px;
		height: auto;
		ul {
			li {
				&:last-child {
					display: inline-block;
					max-width: 100%;
					a {
						font-weight: 300;
						font-size: 18px;
						color: #999;
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						&:hover {
							text-decoration: none;
						}
					}
				}
				&:not(:first-child)::before {
					color: inherit;
				}
			}
		}
		&.showThreedots::before {
			display: none;
		}
	}
}

.picsioThemeLight {
	.wrapperBreadcrumbs {
		&::after {
			background: linear-gradient(to right, rgba(244,244,244,1), rgba(244,244,244,0)); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
		// @media screen and (max-width: $mediaDevice) {
		// 	&::before {
		// 		background: linear-gradient(to right, rgba(244,244,244,1), rgba(244,244,244,0)) !important;
		// 	}
		// }
	}
	.breadCrumbs ul li {
		color: $darkgray;

		&:not(:first-child)::before {
			color: $lightgray;
		}

		&:not(:last-child) {
			span {
				&:hover {
					color: $brandColorLightTheme;
				}
			}
		}
		&:last-child,
			&.disabled {
				span {
					color: $darkgray;
				}
			}
		@include media('<tablet') {
			color: $gray;
		}
	}
}
