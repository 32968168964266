.options {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 26px;
}

.optionItem {
  margin: 0 0 10px;
}

.optionName {
  display: flex;
  gap: 8px;
  padding: 5px 14px;
  align-items: center;
  justify-content: space-between;
  background: var(--background-sidebar-color);
}

.optionNameTitle {
  display: flex;
  gap: 8px;
  align-items: center;
}

.optionValues {
  padding: 0 0 0 12px;
  margin: 0;
  list-style: none;
}

.optionValue {
  display: flex;
  gap: 8px;
  padding: 5px 14px;
  align-items: center;
  justify-content: space-between;
}

.optionValueWithoutVariants {
  color: var(--secondary-dark-color);

  sup {
    font-style: italic;
  }
}

.form {
  display: flex;
  gap: 4px;

  :global(.PicsioInput) {
    width: 100%;
  }
}

.formButtons {
  display: flex;
}
