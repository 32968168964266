.userGroups {
  margin-bottom: 20px;
}

.userGroup {
  margin-top: 15px;
  margin-left: 44px;
}

.userGroupsOpener {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 44px;
  position: relative;
  background-color: #212121;
  height: 40px;

  @include media('<desktop') {
    margin-left: 15px;
  }

  &::before {
    content: ' ';
    width: 0;
    height: 0;
    display: block;
    border-color: transparent transparent transparent currentColor;
    border-style: solid;
    border-width: 5px 0 5px 6px;
    position: absolute;
    top: 15px;
    left: 20px;
    transform: rotate(90deg);
  }

  &.isClosed {
    &::before {
      transform: rotate(0);
    }
  }
}

.picsioThemeLight {
  .userGroupsOpener {
    background-color: $silver;
  }
}

.myTeamUser {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #ccc;
  padding: 10px 15px;
  margin-bottom: 1px;

  &:hover,
  &.isActive {
    color: #fff;
  }

  .buttonSmall {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .userInviteStatus {
    top: 10px;
    left: 53px;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  @include media('>=desktop') {
    padding: 0;
    margin-bottom: 20px;
    flex-wrap: nowrap;

    .userInviteStatus {
      top: 0;
      left: 38px;
    }

    .checkbox {
      top: -10px;
      left: -10px;
    }

    .buttonSmall {
      top: 0;
      right: 0;
    }
  }
}

.myTeamUserAvatar {
  width: 50px;
  height: 50px;
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;

  & .statusIndicator {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: $lightgreen;
    border: 2px solid #333333;
    border-radius: 50%;
  }
}

.picsioThemeLight {
  .statusIndicator {
    border-color: #EBEBEB;
  }
}

.myTeamUserText {
  padding-left: 15px;
  line-height: 1.25;
}

.myTeamUserName {
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  overflow: hidden;
}

.myTeamUserRole {
  font-size: 12px;

  [class*="icon"] {
    margin-right: 4px;
  }

  &.isError {
    color: $error;
  }
}

.picsioThemeLight {
  .myTeamUser {
    color: #666;

    &:hover,
    &.isActive {
      color: $black;
    }
  }
}



.author {
  position: relative;
  display: flex;
  align-items: flex-start;
  color: #666;
  font-weight: 400;
  margin-bottom: 15px;

  &.hidden {
    margin-bottom: 0;
  }

  .authorAvatar {
    margin-right: 10px;
  }
}

.authorText {
  flex: 1;
}

.authorName {
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}

.authorAdditional {
  position: relative;
  overflow: hidden;
  font-size: 10px;
  color: #666;
  padding: 4px 0 0 0;
}

.picsioThemeLight {
  .authorName {
    color: #666;
  }

  .authorAdditional {
    color: #999;
  }
}
