.toolbarDropdownItem {
  &.disabled {
    opacity: 0.3;

    &:hover {
      cursor: not-allowed;
      background: none;
      color: inherit;
    }
  }
}

.sortType.sortType {
  display: flex;
  justify-content: space-between;

  &:hover {
    .sortItems {
      opacity: 1;
    }
  }

  &.active {
    .sortItems {
      opacity: 1;
    }
  }
}

.sortItems {
  display: flex;
  opacity: 0;
}

.listItem {
  cursor: pointer;
  width: 100%;
}

.sortItem {
  display: inline-block;
  padding: 0 6px;
  margin: 0 2px;
  line-height: 25px;
  font-size: 14px;
  cursor: pointer;

  &.active {
    font-weight: 600;
    background: rgba(209, 209, 209, 0.27);
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.50);
    border-radius: 3px;
  }
}
