.billingCard {
  display: inline-flex;
  flex-flow: row wrap;

  .billingCard {
    &__title {
      flex: 0 0 100%;
      margin-bottom: 26px;

      > h3 {
        margin: 0;
      }
    }

    &__info {
      flex: 0 0 50%;
      display: inline-flex;

      &__logo {
        width: 65px;
        height: 42px;
        margin-right: 15px;
        background: url('https://assets.pics.io/img/cards.png') no-repeat left top / auto 100%;

        &.iconVisa {
          background-position: 0 top;
        }
        &.iconMastercard {
          background-position: -66px top;
        }
        &.iconDiscover {
          background-position: -132px top;
        }
        &.iconAmericanexpress {
          background-position: -198px top;
        }
        &.iconDinersclub {
          background-position: -264px top;
        }
        &.iconJcb {
          background-position: -330px top;
        }
        &.iconUnknown {
          width: 100%;
        }
      }
    }

    &__button {
      border-radius: 10px;
      padding: 10px;
      height: auto;
    }
  }
}
