.wrapperDownloadDialog {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1001; // was 4

	&:empty {
		display: none;
	}

	.downloadDialog {
		.simpleDialogContentInner {
			box-sizing: border-box;
		}

    .UIInputRange {
      margin: 18px 0;
    }

		&__presets {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-end;
      margin-bottom: 18px;

      &__select {
				min-width: 0;
				flex: 1 auto;

				.#{$picsioSelectClassName} {
					&__display {
						max-width: 100%;
					}
				}
      }

      &__save {
				flex: 0 30px;
        margin-left: 2px;

        > button {
          width: 30px;
          height: 30px;
          display: flex;
          color: var(--input-text-color);
          background-color: var(--background-input-color);

          @include media('<desktop') {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    &__settings {
      &__row {
        display: flex;
        margin-bottom: 18px !important;

        > div {
          flex: 0 50%;

          &:first-child {
            margin-right: 8px;
          }
        }

        .#{$picsioSelectClassName} {
          min-width: 100px;
        }
      }
    }

		.simpleDialogBox {
			width: 340px;
		}

		.UICheckbox + .UICheckbox,
		.UICheckbox + .organizeByCollections {
			margin-top: 15px;
		}

    .PicsioInputAdornment {
      @include media('<desktop') {
        box-sizing: border-box;
      }
    }

		.gridPopupDownload {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.UIInputIncremental {
				position: relative;
				padding: 24px 0 0;
				margin: 0 0 15px;
			}

			.UIInputIncremental__label {
				position: absolute;
				left: 0;
				top: 0;
				font-size: 14px;
			}
		}

		.partHalfPopupDownload {
			width: 48%;

			&:nth-child(even) {
				align-self: flex-end;
			}
			&:nth-child(1) {
				position: relative;
				z-index: 3;
			}
		}
	}

	.simpleDialog .simpleDialogBox .simpleDialogContent .simpleDialogContentInner {
		.partHalfPopupDownload {
			margin-bottom: 15px;
		}
	}
}

/** Theme light */
.picsioThemeLight {

	.wrapperDownloadDialog {
		.popupDownload {
			.gridPopupDownload {
				.UIInputIncremental__label {
					color: #666;
				}
			}
		}

		.UIInputRangeLabel {
			color: #666;
		}
	}
}

.sync-loading {
  animation: syncLoading 1s linear infinite;
}

@keyframes syncLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
