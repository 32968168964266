.external-preview {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  iframe {
    width: 100%;
    height: 100%;
    border: unset;
  }
  .placeholderExternalDeny {
    padding: 3rem;
    background: rgba(#000, .6);
    box-shadow: 0 0 2.1rem #000;
    border-radius: 1.5rem;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.4em;
    text-shadow: 0 0 0.3rem #000;

    p {
      margin: 0;
    }
    button {
      margin-top: 1rem;
      display: inline-flex;
    }
  }
}
