@import 'cssVariables';

.catalogViewPlaceholderText {
  color: #999;
  line-height: 22px;
  font-size: 16px;
  padding: 78px 25px 20px;

  h2 {
    @include h2;
  }

  .tag {
    margin: 4px 4px 0 0;
    vertical-align: bottom;
  }

  .picsioThemeLight & {
    color: #666;
  }
}

.catalogViewPlaceholderTextInner {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
