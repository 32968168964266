@import '../../../css/variables';

.simpleDialog.addUrlsDialog {
  .simpleDialogBox {
    max-width: 1000px;
    width: 95%;
  }
}

.PicsioImportMenu {
  /** hardcode: data-popper-reference-hidden - works incorrectly in this case */
  &.PicsioPopper--openned.PicsioPopper--hidden[data-popper-reference-hidden="true"] {
    visibility: inherit;
    pointer-events: all;
  }

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    margin: 0;
    padding: 0;
    border: none;
    font-size: 200px;
    opacity: 0.01;
    cursor: pointer;
  }

  &__item {
    cursor: pointer;
    padding: 10px 30px;
    position: relative;
    display: flex;
    align-items: center;

    .PicsioMenuItem__icon {
      text-align: left;
      width: 16px;
      margin: -1px 11px 0 0;
      color: currentColor;
      font-size: 16px;
    }
  }
}

.PicsioUrlsList {
  padding: 0 0 10px;
  margin: 0;
  max-height: 30vh;
  list-style: none;
  overflow: auto;
  font-family: $base-font-mono;
  font-size: 0.9em;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    background: var(--separate-border-color);
    padding: 0 0 0 10px;
    margin-bottom: 3px;
  }

  .text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.PicsioUrlsListLabel{
  margin: 10px 0;
}
