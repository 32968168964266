.picsioProgressBar {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #fff;
	font-size: $base-font-size;

	.holder {
		width: 80%;
		max-width: 400px;
		margin: auto;
	}

	.text {
		margin: 0 0 0.5em;
		font-size: 1.2em;
	}

	.bar {
		position: relative;
		height: 6px;
		border-radius: 3px;
		overflow: hidden;
		border: 1px solid #fff;
	}
	.percent {
		height: 100%;
		background: #fff;
		position: absolute;
		left: 0;
		top: 0;

		&.pending {
			width: 20% !important;
			animation: loading 1.5s linear infinite;
			border-radius: 3px;
		}
	}
}

@keyframes loading {
	from {
		left: -20%;
		width: 20%;
	}
	to {
		left: 100%;
		width: 80%;
	}
}

.picsioThemeLight {
	.picsioProgressBar {
		background: rgba(255, 255, 255, 0.7);
		color: #000;

		.bar {
			border-color: #000;
		}
		.percent {
			background: #000;
		}
	}
}