.picsioSpinner {
	min-height: 50px;
	font-weight: 100;
	color: #fff;
	@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg) }}
	@keyframes rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

	@-webkit-keyframes bounce {
		0%, 100% { -webkit-transform: scale(0.0) }
		50% { -webkit-transform: scale(1.0) }
	}
	@keyframes bounce {
		0%, 100% {
			transform: scale(0.0);
			-webkit-transform: scale(0.0);
		} 50% {
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		}
	}
	.innerPicsioSpinner {
		text-align: center;
		.spinner {
			display: inline-block;
			vertical-align: middle;
			width: 40px;
			height: 40px;
			margin: 0 auto;
			-webkit-animation: rotate 2.0s infinite linear;
			animation: rotate 2.0s infinite linear;
			div,
			span {
				width: 60%;
				height: 60%;
				display: inline-block;
				position: absolute;
				top: 0;
				background: #fff;
				border-radius: 100%;

				-webkit-animation: bounce 2.0s infinite ease-in-out;
				animation: bounce 2.0s infinite ease-in-out;
			}
			span{
				top: auto;
				bottom: 0px;
				-webkit-animation-delay: -1.0s;
				animation-delay: -1.0s;
			}
		}
		.titleSpinner {
			display: none;
			font-size: 16px;
			padding: 20px 14px 5px 14px;
			vertical-align: middle;
			font-weight: 300;
			&.show {
				display: inline-block;
			}
		}
		.spinnerProgress {
			display: block;
			margin-top: 10px;
		}
		.textSpinner {
      max-width: 160px;
			display: none;
			margin: 0 auto;
			font-size: 16px;
			line-height: 20px;
			padding-top: 20px;
      text-align: center;
      font-weight: bold;

			&.show {
        display: flex;
        align-items: center;
        justify-content: center;
			}
		}
	}
	&.blockUI {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1002;
		background: rgba(0,0,0,0.9);
		.innerPicsioSpinner {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			transform: translateY(-50%);
			.spinner {
				display: block;
			}
			.titleSpinner {
				padding-left: 0;
				font-size: 24px;
				&.show {
					display: block;
				}
			}
			.textSpinner {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}
	&.partial {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.6);
		.innerPicsioSpinner {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -20px;
			width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
		}
	}
	&.center {
		.innerPicsioSpinner {
			.textSpinner {
				text-align: center;
			}
		}
	}
}

.picsioThemeLight {
	.picsioSpinner {
		color: #999;
		.innerPicsioSpinner {
			.spinner {
				div,
				span {
					background: $brandColorLightTheme;
				}
			}
		}
		&.blockUI {
			background: rgba(255,255,255,.9);
		}
		&.partial {
			background: rgba(255,255,255,.6);
		}
	}
}
