@mixin placeholder {
  &::-webkit-input-placeholder {@content}

  &:-moz-placeholder {@content}

  &::-moz-placeholder {@content}

  &:-ms-input-placeholder {@content}
}

textarea,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
[contenteditable="true"] {
  border: none;
  border-top: 1px solid #181818;
  border-bottom: 1px solid #353535;
  background: #303030;
  font: normal 14px $base-font;
  margin: 0;
  padding: 4px 10px;
  line-height: 20px;
  height: $formElementHeight;
  box-sizing: border-box;
  -webkit-appearance: initial;
  border-radius: 0;
  color: #999;
  width: 100%;
  display: block;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $brandColorLightTheme;
    background: #000;
    color: #fff;
    border-color: #000;
  }

  &[disabled] {
    background: rgba(48, 48, 48, 0.2);
    color: #999;
    border-top-color: rgba(24, 24, 24, 0.2);
    border-bottom-color: rgba(53, 53, 53, 0.2);

    cursor: default;
    -webkit-text-fill-color: #666; //fix for safari
  }

  @include placeholder {
		color: #505050;
		opacity: 1;
	};

  @include media('<desktop') {
    height: $formElementHeightMobile;
    padding: 14px 10px;
  }
}

// we remove the arrows in Firefox, because when you click the arrows, the value is not saved;
input[type="number"] {
  -moz-appearance: textfield;
}

[contenteditable="true"] {
  height: 64px;
  overflow: auto;
  white-space: pre-line;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

textarea {
  height: 64px;
  overflow: auto;
}

.picsioDefBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: normal 14px $base-font;
  cursor: pointer;
  text-decoration: none;

  &:not(.picsioLink, .picsioLinkForShare) {
    background: linear-gradient(to bottom, #333333, #282828);
    border-top: 1px solid #333333;
    border-bottom: 1px solid #171717;

    &:not(.disable):hover {
      background: #333;
    }
  }

  &:not(.picsioLink) {
    color: #999999;
    min-width: 140px;
    padding: 0 10px;
    height: $formElementHeight;

    &:not(.disable):hover {
      color: #fff;
    }

    &:not(.disable):active {
      background: linear-gradient(to bottom, #212121, #282828);
      border-top-color: #141414;
      border-bottom-color: #343434;
    }

    &.disable {
      background: #292929;
      border-color: #292929;
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
      background-clip: padding-box;
    }

    &.btnRemove {
      color: $error;

      &:not(.disable):hover {
        background: $error;
        border-color: $error;
        color: #fff;
      }

      &:not(.disable):active {
        color: #fff;
        background: linear-gradient(to bottom, #AE0101 40%, $error);
        border-color: #AE0101;
        border-top-color: #8a0909;
        border-bottom-color: #CB0000;
      }
    }

    &.btnCallToAction {
      background: linear-gradient(to bottom, #FECB2E, #FDBB2C);
      border: 1px solid #F6B45B;
      color: #000;

      &:not(.disable):hover {
        color: #000;
        background: #FECB2E;
        border-color: #FECB2E;
      }

      &:not(.disable):active {
        background: linear-gradient(to bottom, #FDAE2A, #FECA2E);
        border-color: #F6B45B;
        color: #000;
      }

      .svg-icon {
        margin-right: 8px;
      }
    }

    .PicsioIcon svg {
      font-size: 20px;
    }
  }

  @include media('<desktop') {
    &:not(.picsioLink) {
      height: $formElementHeightMobile;
      padding: 14px 10px;
    }

    &:not(.picsioLink) {
      height: $formElementHeightMobile;
    }
  }
}

.picsioLink {
  color: $brandColor;
  text-decoration: none;

  &:not(.deactivated) {
    cursor: pointer;

    &:hover {
      color: #ffff00;
    }
    &:active {
      color: $brandColor;
    }
  }
}

.picsioThemeLight {

  textarea,
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  [contenteditable="true"] {
    border-top-color: #dfdede;
    border-bottom-color: #dfdede;
    background: #f4f4f4;
    color: $darkgray;

    &:focus {
      background: #fff;
      color: #000;
      border-color: #fff;
    }

    @include placeholder {
			color: $lightgray;
		};

    &[disabled] {
      //opacity: 0.5;
      background: rgba(244, 244, 244, 0.3);
      color: $darkgray;
      border-top-color: rgba(223, 222, 222, 0.3);
      border-bottom-color: rgba(244, 244, 244, 0.3);

      cursor: default;
      -webkit-text-fill-color: #666; //fix for safari
    }
  }

  .picsioDefBtn {
    &:not(.picsioLink, .picsioLinkForShare) {
      background: linear-gradient(to bottom, #F0F0F0, #e6e6e6);
      border: 1px solid #d1d1d1;
      border-bottom-color: #b9b9b9;

      &:not(.disable):hover {
        background: #f4f4f4;
        border-color: #D1D1D1;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.10);
      }
    }

    &:not(.picsioLink) {
      color: #2b2b2b;

      &:not(.disable):hover {
        color: #2b2b2b;
      }

      &:not(.disable):active {
        background: linear-gradient(to bottom, #e5e5e4, #f0f0f0);
        border-color: #D1D1D1;
      }

      &.disable {
        border-color: #E2E2E2;
        border-bottom-color: #D6D6D5;
        background: #f4f4f4;
      }

      &.btnCallToAction {
        border: 1px solid #397CE0;
        background: linear-gradient(to bottom, #4196F8, #336AF6);
        color: #fff;

        &:not(.disable):hover {
          color: #fff;
          border-color: #3E90F7;
          background: #3B94FB;
          background-image: linear-gradient(0deg, rgba(47, 101, 250, 0.10) 0%, rgba(47, 101, 250, 0.00) 100%);
          box-shadow: 0 2px 4px 0 rgba(59, 148, 251, 0.50);
        }

        &:not(.disable):active {
          background: linear-gradient(to bottom, #2e64fb, #3c96fb);
          border-color: #397CE0;
          border-top-color: #224599;
          color: #fff;
        }
      }

      &.btnRemove {
        color: $error;

        &:not(.disable):hover {
          background: $error;
          border-color: $error;
          color: #fff;
        }

        &:not(.disable):active {
          color: #fff;
          background: linear-gradient(to bottom, #AE0101 40%, $error);
          border-color: #AE0101;
          border-top-color: #8a0909;
          border-bottom-color: #CB0000;
        }
      }
    }
  }

  .picsioLink {
    color: $brandColorLightTheme;

    &:hover {
      color: #0099ff;
    }

    &:active {
      color: $brandColorLightTheme;
    }
  }
}
