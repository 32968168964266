@import 'cssVariables';

.modifiedField {
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: var(--secondary-contrast-color);
	opacity: 0.2;
	transition: opacity 0.3s ease;

	&:hover {
		opacity: 1;
	}

	.dropdown & {
		right: 20px;
	}

	.icon-sync {
		animation: spin 3s linear infinite;
	}


	@include media('<=desktop') {
		height: 50px;
	}
}

.inputWithModifiedField {
	position: relative;
  margin-bottom: 15px;
}

.inputWithModifiedFieldWithIcon {
	textarea, input[type="text"], input[type="number"], input[type="password"], input[type="date"], [contenteditable] {
		padding-right: 30px;
	}
}

.markWithModifiedFieldWithIcon {
	display: inline-block;
	position: relative;
	padding-right: 30px;
}

.customFieldWithIcon {
	position: relative;

  input[type="text"], input[type="number"], input[type="password"], input[type="date"], [contenteditable] {
		padding-right: 30px;
	}

  textarea {
    padding-right: 30px;
  }

	.modifiedField {
		top: 17px;
    bottom: 0;
	}
}

.customInputField.customFieldWithIcon {
  .modifiedField {
    right: 10px;
  }
  .PicsioInput--disabled {
    textarea {
      pointer-events: all;
    }
  }
}

.customFieldSelectWithModifiedField {
	.modifiedField {
		right: 30px;
    top: initial;
    bottom: 0;
    height: 30px;
	}
}

.customFieldBooleanWithModifiedField {
	&.customFieldWithIcon {
		padding-right: 30px;
	}
	.modifiedField {
		top: -6px;

		@include media('<=desktop') {
			height: 30px;
		}
	}
}
