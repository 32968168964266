@import 'cssVariables';

.bestMatchSearchHint {
  transition: transform 0.4s ease, margin-bottom 0.4s ease, background-color 0.4s ease;
	transform: translate3d(0,0,0);
	width: 100%;
	box-sizing: border-box;
	padding: 12px 12px;
  background-color: $brandColor;
	color: #000000;
	position: relative;
	z-index: 1;
	margin-top: 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 300;
  justify-content: space-between;

  .text {
    line-height: normal;
    margin-left: 8px;
  }

  #applyBestMatch {
    padding: 4px 16px;
    margin-right: 9px;
  }

  #closeApplyBestMatch {
    opacity: 0.4;
    color: #000000;
  }

  .baseStyle {
    display: flex;
    align-items: center;
  }
}

.picsioThemeLight {
	.bestMatchSearchHint {
    background-color: $brandColorLightTheme;
    color: #ffff;

    #closeApplyBestMatch {
      opacity: 0.4;
      color: #ffff;
    }
  }
}
