.exportDialogWrapper {
	z-index: 999;

	.simpleDialog.exportDialog {
		color: red;
		.simpleDialogContent {
			padding-left: 25px;

			@include media('<tablet') {
				padding: 0;
			}

			.simpleDialogContentInner {
				padding-left: 25px;
				overflow: visible;

        &.noOverflow {
          overflow: hidden;
        }
				@include media('<tablet') {
					padding: 0 25px;
				}
			}
		}
		.exportDialog-row {
			padding: 10px 25px;
			margin: 0 -25px;
			border-radius: 5px;

			&-expanded {
				background: #1f1f1f;
				overflow: hidden;
			}
		}
		.exportDialog-checkboxes {
			padding: 5px 0 0;
			margin: 15px -25px -20px 0;
			list-style: none;
			overflow: auto;
			max-height: 50vh;

			li {
				padding-bottom: 25px;
			}
		}
	}
}
.picsioThemeLight .exportDialogWrapper {
	.simpleDialog.exportDialog {
		.exportDialog-row {
			&-expanded {
				background: #f2f2f2;
			}
		}
	}
}
