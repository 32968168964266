.liveSupport {

  &Backdrop {
	  position: fixed;
	  top: 0;
	  left: 0;

	  width: 100vw;
	  height: 100vh;
	  background: rgba(51, 51, 51, 0.7)
  }

  &Container {
	  position: relative;
  }

  &Speech {
	margin: 0;
	position: absolute;
	bottom: 70%;
	left: 120%;
	width: 250px;
	padding: 16px;

	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
    border-radius: 8px;
	background: #FFFFFF;

	font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    
    color: #333333;
	pointer-events: none;

	&::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 10px;
    top: 100%;
    border: 4px solid;
    border-color: #fff transparent transparent #fff;
	}
  }


}