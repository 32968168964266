.pageTabsContentNotifications {
	.notificationSettingsRadio:last-child {
		margin-bottom: 12px;
	}
	&__slackBtn{
		cursor: pointer;
	}
}

.notificationSettings {
	font-size: 14px;
	// color: #ccc;
	margin-bottom: 50px;
	max-width: 500px;

	.integrationsContent & {
		margin: 30px 0 0;
	}
}

.notificationSettingsTitle {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 300;
	margin-bottom: 17px;
	color: #fff;

	.pageItemTitleColor {
		font-weight: 300;
	}
}

.notificationSettingsDescription {
	margin: 10px 0 25px;
	line-height: 17px;

	.helpLinkBlock {
		margin-top: 15px;
	}

	.warningHelp {
		margin-top: 15px;
	}

	.warning {
		margin-bottom: 15px;
		color: #ff6b00;
	}
}

.notificationSettingsRadios {
	position: relative;
	z-index: 1;
}

.notificationSettingsRadio {
	padding: 10px 15px;
	margin: -10px -15px 10px;
	border-radius: 10px;

	.UIRadio {
		margin-bottom: 0;
		line-height: 18px;
	}

	// .UIRadio__input {
	// 	margin-top: 2px;
	// }
}

.notificationSettingsEvents {
	padding: 36px 0 0;
	border-radius: 10px;
	background-color: #222;
	margin: -48px -15px 12px;
	position: relative;
	line-height: 17px;

	.integrationsContent & {
		margin-bottom: 12px;
	}

	.UICheckbox {
		margin-bottom: 10px;

		.UICheckbox__label {
			white-space: normal;
		}
	}

	.UICheckbox__checkbox {
		margin-top: 1px;
	}

	&.isOpened {
		padding: 48px 0 10px;
	}
}

.notificationSettingsEventsOpener {
	position: absolute;
	top: 2px;
	right: 5px;
	z-index: 2;
	cursor: pointer;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 12px;
	transform: rotate(90deg);

	.isOpened & {
		transform: rotate(180deg);
	}

	&:hover {
		color: #fff;
	}
}

.notificationSettingsEvent {
	padding: 10px 15px;
}

.notificationSettingsEventDescription {
	padding-left: 25px;
}

.notificationSettingsTypesIsEmpty {
	padding: 15px;
}

@include media('<desktop') {
	.notificationSettingsRadios {
		padding: 0 15px;
	}

	.notificationSettingsEvents {
		margin-left: 0;
		margin-right: 0;
	}
}

.picsioThemeLight {
	.notificationSettingsTitle {
		color: #222;
	}

	.notificationSettingsEvents {
		background-color: #f4f4f4;
		color: #666;
	}

	.notificationSettingsEventsOpener {
		&:hover {
			color: #000;
		}
	}
}
