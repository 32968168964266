.ListItem {
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 24px;
  flex-wrap: nowrap;
  color: #ccc;

  :global(.picsioThemeLight) & {
    color: #999;
  }

  &:hover {
    cursor: default;
    color: #fff;

    :global(.picsioThemeLight) & {
      color: #666;
    }
  }



  .leftGroupLabel,
  .rightGroupLabel {
    display: flex;
  }

  .leftGroupLabel {
    min-width: 0;
    & div:first-child {
      margin-right: 14px;
    }

    .label {
      overflow: hidden;
    }
  }

  .rightGroupLabel {
    & div:first-child {
      margin-right: 40px;
    }
  }

  .label {
    display: flex;
    align-items: center;


    font-family: 'Fira Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    color: currentColor;

    &.isFolder:hover {
      cursor: pointer;
    }
  }

  .icon {
    margin-right: 8px;
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .date {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    width: 101px;
  }
}
