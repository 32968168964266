.addingBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 64px;
  background-color: #303030;
  padding: 0 12px;
  box-sizing: border-box;

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0 0 0 8px;
  }

  &__arrowIconContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__arrowIcon {
    width: 16px;
    height: 16px;

    path {
      fill: $brandColor;
    }
  }

  &:hover {
    .picsioLink {
      color: #ffff00;
    }
    path {
      fill: #ffff00;
    }
  }
}

.picsioThemeLight {
  .addingBlock {
    background-color: $light-lightgray;

    path {
      fill: $brandColorLightTheme;
    }

    &:hover {
      .picsioLink {
        color: #0099ff;
      }
      path {
        fill: #0099ff;
      }
    }
  }
}

