// styles for UICheckbox react component

$disabledOpacity: 0.3;

.UICheckbox {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;

  &--error {
    .UICheckbox__checkbox {
      box-shadow: inset 0 0 0 1px $error;
    }
  }

  &--checked {
    .UICheckbox__checkbox {
      background: $brandColor;
      box-shadow: none;
      .icon-ok {
        display: block;
      }
    }
  }

  &--partial {
    .UICheckbox__checkbox {
      position: relative;
      background: $brandColor;
      box-shadow: none;
      &::before {
        content: '';
        display: block;
        text-align: center;
        position: absolute;
        top: 7px;
        left: 3px;
        right: 3px;
        height: 1px;
        background-color: currentColor;
      }
    }
  }

  &--disabled {
    cursor: default;
    opacity: $disabledOpacity;
  }

  &__checkbox {
    box-shadow: inset 0 0 0 1px #666;
    height: 15px;
    width: 15px;
    font-size: 12px;
    color: #161616;
    padding: 2px;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 1px;

    .icon-ok {
      display: none;
    }
  }

  &__label {
    line-height: 1.3;
    font-size: 14px;
    color: #ccc;
    white-space: nowrap;
    margin-left: 10px;

    .svg-icon {
      vertical-align: middle;
      margin-top: -3px;
      color: var(--primary-main-color);
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.slideCheckboxWrapper {
  display: flex;
  align-items: center;

  &.inProgress {
    pointer-events: none;
    opacity: 0.9;
  }
}

.slideCheckboxLabel {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
  color: #666;
  font-size: 12px;
  font-weight: 300;
  cursor: default;
  &.searchFiltersSlideLabel {
    order: 2;
    font-size: 14px;
    margin-left: 8px;
    margin-right: 0;
  }
}

.slideCheckbox {
  cursor: pointer;
  width: 25px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  transition: background 0.3s ease, box-shadow 0.1s linear 0.4s;
  background: rgba(204, 204, 204, 0.13);
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px rgba(204, 204, 204, 0.2),
    inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    .slideCheckboxSlider {
      background: rgba(255, 255, 255, 1);
    }
  }
  &Checked {
    background: $brandColor;
    border-color: $brandColor;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    .slideCheckboxSlider {
      background: rgba(255, 255, 255, 0.9);
    }
  }

  &Disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.slideCheckboxSlider {
  position: absolute;
  width: 14px;
  height: 14px;
  line-height: 1;
  border-radius: 50%;
  left: 1px;
  top: 1px;
  transition: background 0.3s ease, left 0.3s linear;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.8);
  color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  [class^='icon-'],
  .slideCheckboxSyncIcon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: $gray;
  }

  svg {
    color: $gray;
    border-radius: 0;
  }

  .icon-share {
    font-size: 10px;
  }

  .slideCheckboxSyncIcon {
    position: absolute;
    z-index: 3;
    left: calc((14px - 10px) / 2);
    top: calc((14px - 10px) / 2);
    background: inherit;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;
    color: $dark-darkgray;
  }

  .slideCheckboxChecked & {
    left: 11px;
    background: $silver;
  }

  .slideCheckboxWrapper.inProgress & {
    .slideCheckboxSyncIcon {
      opacity: 1;
      visibility: visible;
      animation: spin 3s linear infinite;
    }
  }
}
//END styles for UICheckbox react component

.UIInput {
  margin-bottom: 15px;

  &__input {
    position: relative;
    width: 100%;

    input {
      @include media('<desktop') {
        height: 50px;
      }
    }

    &__button {
      font-size: 14px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 30px;
      text-align: center;
      color: #999;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #fff;
      }

      @include media('<desktop') {
        height: 50px;
      }
    }

    // $TODO: remove 'icon_'
    [style*='background-image'] + [class^='icon_'],
    [style*='background-image'] + .svg-icon {
      right: 25px;
    }
  }

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  &--error {
    input {
      box-shadow: 0 0 0 2px $error;
      border: 0;
      &:focus {
        box-shadow: 0 0 0 2px $error;
      }
    }
  }
  &__error {
    font-size: 12px;
    color: $error;
    line-height: 1;
    padding-top: 6px;
  }
}

.shortInput {
  max-width: $widthShort;
}

.mediumInput {
  max-width: $widthMedium;
  margin-bottom: 15px;

  label {
    margin-bottom: 5px;
  }
}

.UIAutocomplete {
  position: relative;

  &__dropdown {
    max-height: 180px;
    overflow: auto;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    li {
      font-size: 18px;
      color: #999999;
      padding: 0 20px;
      height: 60px;
      line-height: 62px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.active,
      &:hover {
        background: #000;
        color: #fff;
      }
    }
    mark {
      color: $brandColor;
      background: none;
    }

    @media screen and (max-width: $mediaDevice) {
      font-size: 16px;

      li {
        padding: 0 15px;
        height: 30px;
        line-height: 32px;
      }
    }
  }
  &__tempValue {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.imageSlider {
  margin-top: 15px;
  margin-bottom: 15px;
  user-select: none;
  max-width: 680px;

  img {
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
  }

  &__img {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &__container {
      position: relative;
      padding-top: 52.95%;
      overflow: hidden;
    }

    &__inActive {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }

    &__active {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
    }
  }

  &__previewLinkHolder {
    margin-top: 10px;
  }

  &__previewLink {
    font-size: 13px;
    color: $brandColor;
    cursor: pointer;
    line-height: 20px;
    padding-left: 5px;
  }

  &__container {
    position: relative;

    &__inner {
      overflow: hidden;
      white-space: nowrap;
      font-size: 0;
    }

    &__item {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
    }

    &__prev,
    &__next {
      position: absolute;
      top: 0;
      bottom: 30px;
      width: 30px;
      display: flex;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      color: #f4f4f4;
      -webkit-user-select: none;
      user-select: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
      }

      .svg-icon {
        margin: auto;
        font-size: 30px;
      }
    }

    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }
  }
}

.UIImagePicker {
  position: relative;
  width: 125px;
  height: 125px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .picsioSpinner.partial {
    border-radius: 50%;
    z-index: 4;

    .innerPicsioSpinner {
      margin-top: 0;
      top: 0;
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    background-color: #111;
    color: #333;
    text-align: center;
    border-radius: 50%;
    font-size: 60px;
    line-height: 125px;
    overflow: hidden;

    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-size: cover;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 50%;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  &__title {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    font-size: 14px;
    line-height: 1.2;
    font-family: $base-font;
    color: #7f7f7f;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    padding: 0 15px;
    box-sizing: border-box;
    background: rgba(38, 37, 37, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    opacity: 0;
    transition: all 0.3s ease;

    [class^='icon-'] {
      font-size: 16px;
      color: $brandColor;
    }

    &__caption {
      margin-top: 10px;
      color: #ccc;
    }
  }

  &__file {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 50%;

    input {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 0;
      width: 100%;
      height: 100%;
      opacity: 0.001;
      cursor: pointer;
    }
  }

  &__remove {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    text-align: center;
    z-index: 5;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    .UIImagePicker__content {
      opacity: 1;

      &__caption {
        color: #fff;
      }
    }
    .UIImagePicker__remove {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .UIImagePicker__title {
      opacity: 0;
    }
  }
}

.UIImagePicker__uploaded {
  .UIImagePicker__content {
    background: rgba(38, 37, 37, 0.8);

    &__caption {
      color: #fff;
    }
  }
}

.UIRadio {
  display: flex;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;

  &__input {
    flex: none;
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    box-sizing: border-box;
    margin-right: 15px;
    position: relative;
  }
  &--checked {
    .UIRadio__input {
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: block;
        border-radius: 50%;
        background: $brandColor;
      }
    }
  }

  &--disabled {
    opacity: $disabledOpacity;
    cursor: default;
  }
}

.UIInputIncremental {
  display: flex;
  line-height: 17px;

  &__input {
    position: relative;
  }

  &__buttons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding: 0 3px;

    span {
      width: 15px;
      cursor: pointer;
      color: #999;
      height: 50%;
    }
  }

  &__label {
    font-size: 12px;
  }

  @include media('<desktop') {
    &__buttons {
      position: static;

      span {
        width: 20px;
        height: 100%;
      }
    }

    &__dec,
    &__inc {
      position: absolute;
      top: 0;
      height: 100%;
      line-height: 30px;
      text-align: center;
    }

    &__dec {
      left: 0;
    }

    &__inc {
      right: 0;
    }

    [type='text'] {
      text-align: center;
      padding: 5px 20px;
    }
  }
}

.UIInput {
  .UIInputDescription {
    font-size: 12px;
    color: #666;
    padding-top: 7px;
  }
}

.UITextarea {
  margin-bottom: 15px;
  position: relative;
  textarea {
    resize: none;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  &__resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    cursor: se-resize;
  }
  .UITextareaDescription {
    font-size: 12px;
    color: #666;
    padding-top: 7px;
  }
  &--error {
    textarea {
      box-shadow: 0 0 0 2px $error;
      border: 0;
      &:focus {
        box-shadow: 0 0 0 2px $error;
      }
    }
  }
  &__error {
    font-size: 12px;
    color: $error;
    line-height: 1;
    padding-top: 6px;
  }
}

/**
 * Input Range
 */
.UIInputRange {
  margin: 10px 0 15px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  &.UIInputRange__disabled {
    opacity: 0.5;
    cursor: default;

    .UIInputRangePresets {
      li {
        cursor: default;
      }
    }
    .UIInputRange__slider,
    .UIInputRange__dot {
      cursor: default;
    }
    .UIInputRange__line-0 {
      background: #4b4b4b;
    }
  }

  .UIInputRangeLabel {
    display: block;
    margin: 0 60px 10px 0;
    height: 30px;
    line-height: 33px;
    color: #fff;
    font-size: 14px;

    @include media('<desktop') {
      line-height: 50px;
      height: 50px;
    }
  }

  .UIInputIncremental {
    float: right;
    margin: 0;
    padding: 0;
    width: 54px;
    text-align: center;
    input {
      text-align: center;
    }
  }

  .UIInputRange__slider {
    height: 16px;
    position: relative;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 0 15px;

    .thumb {
      background: #bfbfbf;
      height: 16px;
      width: 16px;
      top: -3px;
      position: absolute;
      cursor: move;
      border-radius: 50%;
      z-index: 2;
      border: 3px solid #fff;
    }
    .track-0 {
      display: block;
      background: $brandColor;
      height: 100%;
      position: relative;
      border-radius: 8px;
    }
  }

  .UIInputRangePresets {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    text-align: center;
    font-size: 14px;
    line-height: 30px;

    li {
      flex: 1 1;
      height: 30px;
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      cursor: pointer;
      user-select: none;

      &.UIInputRangeActive {
        color: #000;
        background: #fff;
      }
    }
  }
}

.picsioInputDateRange {
  .customSelect {
    width: 100%;
  }
  .customTimeInputDateRange {
    padding-top: 5px;
    display: flex;
    width: 355px;
    justify-content: space-between;

    .dateInputs {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dateMobileInputs {
      width: 100%;
      max-width: 355px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      [type='date'] {
        margin-bottom: 5px;
      }
    }

    [type='text'] {
      width: 175px;
    }

    @media screen and (max-width: $mediaDevice) {
      width: 100%;
      max-width: 400px;
      flex-wrap: wrap;
    }
  }
  .UIInput {
    width: 175px;
  }
}

.UIInputFile {
  max-width: 320px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &__content__btn {
    display: inline-block;
    position: relative;

    [type='file'] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.01;
      font-size: 200px;
      cursor: pointer;
    }
  }

  .picsioDefBtn {
    min-width: 0;
  }

  &__filename {
    font-size: 12px;
    padding-left: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/**
 * Theme light
 */
.picsioThemeLight {
  .UIInput {
    &__input {
      &__button {
        color: #666;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .UICheckbox {
    &__label {
      color: #666;
    }

    &--checked {
      .UICheckbox__checkbox {
        background: $brandColorLightTheme;
        color: #fff;
      }
    }
    &--partial {
      .UICheckbox__checkbox {
        background: $brandColorLightTheme;
        color: #fff;
      }
    }
  }

  .slideCheckbox {
    background-color: rgba(204, 204, 204, 0.15);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    .slideCheckboxSlider {
      font-size: 10px;
      background: rgba(255, 255, 255, 0.8);
      svg,
      [class^='icon-'],
      .slideCheckboxSyncIcon {
        color: $brandColorLightTheme;
      }
    }
    &:hover {
      .slideCheckboxSlider {
        background: #fff;
      }
    }

    &.slideCheckboxChecked {
      background: $brandColorLightTheme;
      border-color: $brandColor;
      .slideCheckboxSlider {
        background: rgba(255, 255, 255, 0.95);
      }
      &:hover {
        .slideCheckboxSlider {
          background: #fff;
        }
      }
    }
  }

  .UIRadio {
    &__input {
      border-color: #676767;

      &::before {
        background-color: $brandColorLightTheme;
      }
    }
  }

  .UIImagePicker {
    &__preview {
      color: #f4f4f4;
      background-color: #ebebeb;
    }

    &__content {
      background: rgba(255, 255, 255, 0.3);

      [class^='icon-'] {
        color: $brandColorLightTheme;
      }
    }

    &:hover {
      .UIImagePicker__content {
        &__caption {
          color: #222;
        }
      }
    }

    &__title {
      color: #ccc;
    }
  }

  .UIImagePicker__uploaded {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);

    .UIImagePicker__content {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .imageSlider {
    &__previewLink {
      color: $brandColorLightTheme;
    }
  }

  .UIInputIncremental {
    [type='text'] {
      border: 1px solid #fff;
      border-top-color: #dfdede;
    }
    &__buttons {
      span {
        color: #333333;
      }
    }
  }

  .UIAutocomplete {
    &__dropdown {
      background: #fff;
      border: 1px solid #e0e0e0;
    }

    li {
      color: #666;

      &.active,
      &:hover {
        color: #666;
        background: #ccc;
      }
    }
    mark {
      color: $brandColorLightTheme;
    }
  }

  .UIInputRange {
    &.UIInputRange__disabled {
      .UIInputRange__dot {
        background: #807f7f;
        border-color: #666;
      }
      .UIInputRange__line-0 {
        background: #4b4b4b;
      }
    }

    &__slider {
      background: rgba(255, 255, 255, 0.5);
    }

    .UIInputRangePresets {
      li {
        color: #666;
        background: #cfcfcf;

        &.UIInputRangeActive {
          color: #000;
          background: #fff;
        }
      }
    }
  }
}

