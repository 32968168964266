.notice {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  padding: 10px;
  color: #666;
  font-weight: 300;
  font: 300 14px/1.16666 "Fira Sans", Helvetica, Arial, sans-serif;

  &__text {
    margin-bottom: 40px;
    font-size: 24px;
  }

  &__icon {
    font-size: 75px;
    margin-bottom: 40px;
  }
}