@import 'cssVariables';

.customFieldsSelectorWrapper {
	display: flex;
	align-items: center;
	min-height: 30px;

	.customFieldsTitle {
		font-size: 14px;
	}

	> .PicsioButton {
		font-size: 14px;

		@include media('>=desktop') {
			margin-left: 100px;

			&.removeLeftMargin {
				margin-left: 15px;
			}
			#import & {
				margin-left: 0;
			}
      .sharingSettingsBlock & {
        margin-left: 5px;
      }
		}
	}
  .labelItemSearchFilters {
    .sharingSettingsBlock & {
      padding: 7px 0 0 5px;
    }
  }

  .sharingSettingsBlock & {
    margin-top: 10px;
  }
	.itemSearchFilters {
		width: 100%;
		margin-bottom: 0;
	}
}
