.placeholder {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 30px 0;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-dark-color);

  :global(.detailsProcessingItem) {
    flex: 1;
    text-align: left;
    gap: 10px;
    color: var(--secondary-main-color);
  }

  :global(.detailsProcessingItemIcon) {
    font-size: 16px;
  }
}
