.PresetsList {
  position: relative;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  list-style: none;

  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.PresetsItem {
  display: flex;
  flex-direction: column;
  border: 2px solid #242424;
  cursor: pointer;
  transition: border 250ms ease-in-out;

  :global(.picsioThemeLight) & {
    border: 2px solid #E5E5E5;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.active {
  border: 2px solid var(--primary-main-color) !important;
}

.defaultImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #333;

  :global(.picsioThemeLight) & {
    background-color: #fff;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  min-height: 40px;
  background-color: #242424;

  :global(.picsioThemeLight) & {
    background-color: #E5E5E5;
  }

  @media screen and (max-width: 1024px) {
    min-height: 60px;
  }
}

.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
