.indicator {
	display: inline-block;
	width: 30px;
	background: url(https://assets.pics.io/img/kebab-spinner.svg) no-repeat 50% 50%;
	background-size: 30px 30px;
	height: 8px;
	min-width: 30px;
	margin: 0 7px 0 2px;
	opacity: 0.7;
	position: relative;

	.filename + & {
		margin-left: 0;
	}

	& + & {
		margin-left: 0;
	}
}

.PicsioButton .indicator {
  margin: 0;
	background: url(https://assets.pics.io/img/kebab-spinner-white.svg) no-repeat 50% 50%;
  background-size: 100%;
}

.indicatorText {
	font-size: 11px;
	margin: 0 7px;
	align-self: flex-end;
	display: none;
	color: #939393;

	&.autogenerateError {
		display: block;
		margin: -5px 0 15px 16px;
	}

	@media screen and (max-width: $mediaDevice) {
		display: block;

		.detailsPanel__statistics + & {
			margin: -10px 0 15px 0;
		}
	}
}

.jobsStatusSpinner {
	width: 38px;
	height: 26px;
	position: absolute;
	top: 0;
	right: 49px;
	background: url(https://assets.pics.io/img/kebab-spinner.svg) no-repeat 50% 50%;
	background-size: 30px 30px;

	.catalogView_list & {
		top: 27px;
		right: 2px;
		opacity: 0.5;
	}
}

.picsioThemeLight {
	.indicator {
		opacity: 0.6;
		background-image: url(https://assets.pics.io/img/kebab-spinner-white.svg);
	}

  .PicsioButton .indicator {
    background: url(https://assets.pics.io/img/kebab-spinner.svg) no-repeat 50% 50%;
    background-size: 100%;
  }

	.catalogView_list .jobsStatusSpinner {
		background-image: url(https://assets.pics.io/img/kebab-spinner-white.svg);
	}
}
