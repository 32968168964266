.picsioThemeDark  {
  ::-webkit-scrollbar-corner {
    background: transparent !important;
  }
  .jodit_theme_dark.jodit-container {
    border: 1px solid #444444;
  }

  .jodit_theme_dark {
    .jodit-toolbar__box:not(:empty), .jodit-status-bar {
      background-color: #333333;
      border-color: #444444;
    }

    .jodit-toolbar-button__button:hover:not([disabled]),
    .jodit-ui-button:hover:not([disabled]) {
      svg {
        fill: #161616 !important;
      }
      & + .jodit-toolbar-button__trigger:not([disabled]) {
        opacity: 1;
      }
      & .jodit-toolbar-button__text,
      & .jodit-ui-button__text {
        color: #161616 !important;
      }
    }
    .jodit-toolbar-button:hover:not([disabled]) {
      svg {
        fill: #161616 !important;
        stroke:  #161616 !important;
      }
      .jodit-toolbar-button__trigger:not([disabled]) {
        opacity: 1;
      }
    }

    .jodit-toolbar-button__trigger:hover:not([disabled]) {
      opacity: 1;
      svg {
        fill: #161616 !important;
      }
    }

    .jodit-toolbar-button:hover:not([disabled]) {
      svg {
        fill: #161616 !important;
      }
    }
  }
}

.jodit-add-new-line {
  display: none !important;
}
