@import 'cssVariables';
:root,
html.picsioThemeDark {
  --video-button-color: rgba(255, 255, 255, 0.75);
  --comments-range-color: #fff;
}

html.picsioThemeLight {
  --video-button-color: #666;
  --comments-range-color: #3b94fb;
}

/****************************** PREVIEW_VIEW ***********************************/
.preview {
  display: flex;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  .onDragenterPopup {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.9);
    border: 2px solid $brandColor;
    box-sizing: border-box;
    text-align: center;
    .text {
      font-weight: 100;
      font-size: 20px;
      color: #fff;
      vertical-align: middle;
    }
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 0;
    }
    &.show {
      display: block;
    }
  }
  .placeholderMediaFile {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 400px;
    height: 400px;
    z-index: 4;
    margin: -200px 0 0 -200px;
  }
  .wrapperGallery {
    flex: 1;
    order: -1;
    position: relative;
    z-index: 4;
    height: calc(100% - 50px);
    margin: 50px 0 0 50px;
    padding: 0;
    display: block;
    -webkit-transition: margin 0.1s ease;
    -moz-transition: margin 0.1s ease;
    transition: margin 0.1s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    @include media('>=tablet') {
      min-width: 0;
    }
    &.displacementGallery {
      @include media('>=desktop') {
        margin-right: 250px;
        width: calc(100% - 330px);
        .containerMediaFile .innerContainerMediaFile .theMediaFile {
          iframe,
          video,
          audio {
            width: calc(100% - 82px);
          }
        }
      }
    }

    .containerMediaFile {
      height: 100%;
      width: 100%;
      position: relative;
      font-size: 0;
      overflow: hidden;
      -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .innerContainerMediaFile {
        display: block;
        height: 100%;
        position: relative;

        .theMediaFile {
          display: flex;
          align-items: center;
          text-align: center;
          width: 100%;
          height: 100%;

          @media screen and (max-width: 767px) {
            width: 100vw;
          }

          iframe {
            width: 100%;
            height: 100%;
          }

          img,
          canvas {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            transform-origin: center center;
            cursor: move;
          }
        }
        .playNotAllowedVideo {
          position: absolute;
          left: 50%;
          top: 50%;
          height: 80px;
          width: 80px;
          margin: -40px 0 0 -40px;
          cursor: pointer;
          z-index: 2;
          border-radius: 50%;
          background: #fff;
          &::before {
            content: '';
            border-style: solid;
            border-width: 20px 0 20px 30px;
            border-color: transparent transparent transparent #000;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 0;
            height: 0;
            margin: -20px 0 0 -10px;
          }
        }
        .differentVersion {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-style: solid;
          border-color: #ccc;
          display: none;
          pointer-events: none;
          &.show {
            display: block;
          }
          img {
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .wrapperMarkers {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          cursor: all-scroll;
        }
        .active {
          .poiResizer {
            margin: -10px 0 0 -10px;
          }
        }
        .poi {
          position: absolute;
          border: 1px solid #ff3366;
          background: rgba(255, 51, 102, 0.3);
          z-index: 2;

          &.draggable {
            cursor: move;
          }
          &.isFaceRecognition {
            background: rgba(182, 250, 24, 0.5);
            border-color: #b6fa18;
          }
        }

        .tempBoundingBoxTextContainer {
          background: black;
          bottom: 104%;
          position: absolute;
          z-index: 3;
          padding-bottom: 6px;
          padding-top: 6px;
          padding-left: 20px;
          padding-right: 10px;
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          .text {
            color: #ffcc00;
            font-size: 12px;
            margin-right: 9px;
          }
        }

        .poiResizer {
          position: absolute;
          left: 100%;
          top: 100%;
          margin: 10px 0 0 10px;

          &.faceRecogInitial {
            // left: 80%;
            // top: 80%;
            bottom: 10px;
            right: 10px;
            left: unset;
            top: unset;
            margin: unset;
          }

          .poiResizerArrow {
            position: absolute;
            width: 10px;
            height: 10px;
            overflow: hidden;
            cursor: nwse-resize;
            box-shadow: 2px 2px 0px -1px #ff3366;

            &::before {
              content: '';
              width: 150%;
              height: 150%;
              position: absolute;
              left: 35%;
              top: 35%;
              background: #fff;
              transform: rotate(45deg);
            }

            &.isFaceRecognition {
              box-shadow: 2px 2px 0px -1px #b6fa18;
            }
          }

          .poiResizerText {
            position: absolute;
            width: max-content;
            top: 25px;
            left: 0px;
            padding: 8px 12px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            background: #ff3366;
            border-radius: 2px;

            &::after {
              content: ' ';
              position: absolute;
              width: 0;
              height: 0;
              left: 10px;
              top: -2px;
              border: 4px solid;
              transform: rotate(45deg);
              border-color: #ff3366 transparent transparent #ff3366;
            }

            &.isFaceRecognition {
              background: #b6fa18;

              &::after {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                left: 10px;
                top: -2px;
                border: 4px solid;
                transform: rotate(45deg);
                border-color: #b6fa18 transparent transparent #b6fa18;
              }
            }
          }
        }
        .itemHistoryMarker {
          margin: -15px 0 0 -15px;
          position: absolute;
          z-index: 3;
          .circleHistoryMarker {
            display: block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #fff;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            font-size: 18px;
            color: #fff;
            background: #ff3366;
            // cursor: all-scroll;
            cursor: default;
            position: relative;
            z-index: 2;
            &.isFaceRecognition {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #b6fa18;
              i {
                color: black;
              }
            }
          }
          .commentHistoryMarker {
            display: none;
            width: max-content;
            position: absolute;
          }
          &:hover {
            z-index: 3;
            .commentHistoryMarker {
              display: block;
            }
          }
        }
      }
    }

    .galleryArrowPrev,
    .galleryArrowNext {
      position: absolute;
      top: 50%;
      z-index: 11;
      height: 40px;
      margin-top: -20px;
      width: 41px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding: 4px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.25);

        .picsioThemeLight & {
          background: rgba(0, 0, 0, 0.05);
        }

        & .svg-icon {
          display: inline-block;
          margin: auto;
          cursor: pointer;
          opacity: 0.8;
          font-size: 18px;
          color: #fff;
        }
      }
      &.pass {
        cursor: default;
        span {
          display: none;
        }
      }
      &:hover {
        span {
          background: rgba(0, 0, 0, 0.8);
          & .svg-icon {
            opacity: 1;
          }
        }
      }
    }
    .galleryArrowPrev {
      left: 0;
      justify-content: flex-end;
    }
    .galleryArrowNext {
      right: 0;
      justify-content: flex-start;
    }
    .previewViewSpinner {
      background: rgba(0, 0, 0, 0.9);
      .titleSpinner.show {
        display: block;
        padding-left: 0;
        font-size: 24px;
      }
    }
  }

  .itemHistoryMarker {
    margin: -15px 0 0 -15px;
    position: absolute;
    z-index: 2;
    .circleHistoryMarker {
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: 1px solid #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      font-size: 18px;
      color: #fff;
      background: #ff3366;
      cursor: default;
      position: relative;
      z-index: 2;
    }
    .commentHistoryMarker {
      display: none;
      width: 270px;
      position: absolute;
      font-size: 12px;
      background-color: $bgColor;
      color: #ccc;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      padding: 10px 15px;
      line-height: 1.5;

      .authorHistoryMarker {
        margin-bottom: 5px;
        color: #fff;
      }

      .textHistoryMarker {
        font-weight: 300;
        white-space: pre-wrap;
        word-break: break-word;
      }

      a {
        color: $brandColor;
      }
    }
    &:hover {
      z-index: 3;
      .commentHistoryMarker {
        display: block;
      }
    }
  }

  &.trashed {
    .placeholderTrashed {
      display: block;
    }
  }
  .placeholderTrashed {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    .inner {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      color: #ccc;
      .icon {
        font-size: 40px;
        margin-bottom: 15px;
      }
      .text {
        font-weight: 300;
        display: block;
        margin-bottom: 12px;
      }
    }
    &::after {
      content: ' ';
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }

  @include media('<desktop') {
    padding-left: 51px;

    .wrapperGallery {
      // height: 100%;
      margin: 50px 0 0 0;

      &.displacementGallery {
        //fix 70px on mobile devices
        width: auto !important;
      }

      .galleryArrowNext,
      .galleryArrowPrev {
        box-sizing: content-box;
      }

      .containerMediaFile {
        height: 100%;
        margin: 0;
      }
    }
  }

  @include media('<tablet') {
    padding-left: 0;
    padding-bottom: 50px;
  }
}

.theMediaFileAudio {
  display: flex;
  align-items: center;

  audio {
    margin: auto;
    height: 50px;
    width: calc(100% - 100px);
  }
}

.wrapperBeautyVideo {
  width: 100%;
  height: calc(100% - 54px);
  align-self: flex-start;
  display: flex;

  @include media('landscape', '<desktop') {
    height: 100%;
  }

  .compare & {
    padding: 55px 0;
    position: relative;
    box-sizing: border-box;
  }

  .wrapperVideoTag {
    flex: 0 1 100%;
    display: flex;
    flex-flow: column;
    position: relative;

    @include media('>=desktop') {
      flex-flow: row;
    }

    @include media('<tablet') {
      justify-content: center;
    }

    .headVideo {
      position: absolute;
      top: 0;
      z-index: 2;
      text-align: left;
      color: #ccc;
      flex: none;
      padding: 30px 0 10px 10px;
      display: flex;
      align-items: center;
      .titleComparison {
        font-size: 18px;
        font-weight: 200;
      }
      .btnVolumeCurrent {
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
      &.act {
        color: #fff;
      }
    }
    video {
      width: 100%;
      min-height: 0; // fix video aspect ratio in Firefox on small screen
      flex: 1 1 100%;
    }

    .videoHolder {
      width: 100%;
      min-height: 0; // fix video aspect ratio in Firefox on small screen
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      @include media('<tablet') {
        flex: 0 auto;
      }
    }

    .videoPoster {
      pointer-events: none;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      padding-bottom: 50px;
      transition: all 0.3s ease;
      object-fit: contain; // fix aspect ratio on small resolutions
    }
  }
  .videoError {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    font-size: 1rem;
    color: #fff;
    text-shadow: 1px 1px 1px #000;

    .svg-icon {
      vertical-align: text-top;
    }
  }
  .wrapperVideoDialog {
    text-align: left;
  }
}

.compare {
  @include media('<desktop') {
    .fullScreen {
      .wrapperBeautyVideo {
        height: 100%;
      }
    }

    .wrapperBeautyVideo {
      padding: 0;
      height: calc(100% - 50px);

      .videoPoster {
        display: none;
      }

      .titleComparison {
        font-size: 14px !important;
      }

      .headVideo {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        overflow: hidden;
      }

      .wrapperVideoTag {
        height: 100%;
      }

      .videoControls {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }

  @include media('landscape', '<desktop') {
    .wrapperBeautyVideo {
      .wrapperVideoTag {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .videoHolder {
        width: 50%;
        flex: 1 0 50%;
        max-height: calc(100% - 54px); // 54 - height of controls + progress bar
      }

      .videoControls {
        width: 50%;
        margin: auto 0 0 0;
      }
    }
  }

  @include media('portrait', '<desktop') {
    .wrapperBeautyVideo {
      .wrapperVideoTag {
        > div {
          &:nth-child(0) {
            order: 1;
          }

          &:nth-child(3) {
            order: 2;
          }

          &:nth-child(2) {
            order: 3;
          }

          &:nth-child(4) {
            order: 4;
          }
        }
      }

      .videoHolder {
        height: calc(50% - 50px);
      }

      .videoControls {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.popupPlayVideo,
.popupPauseVideo {
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 20px 18px 20px 22px;

  @keyframes animatePopupVideo {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(4);
    }
  }
  animation: animatePopupVideo 0.5s ease-in;

  &.playVideoButton {
    animation: none;
    z-index: 2;
    cursor: pointer;
  }

  &.popupPlayVideo {
    &::before {
      content: '';
      border-style: solid;
      border-width: 11px 0 11px 22px;
      border-color: transparent transparent transparent
        var(--video-button-color);
      width: 0;
      height: 0;
      display: block;
    }
  }
  &.popupPauseVideo {
    &::before,
    &::after {
      content: '';
      border: none;
      width: 6px;
      height: 22px;
      margin: 5px;
      display: block;
      background: var(--video-button-color);
    }
  }
}

.popupControl {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;

  .left {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      color: white;
      circle {
        color: black;
        opacity: 0.5;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: 30px;
    svg {
      color: white;
      circle {
        color: black;
        opacity: 0.5;
      }
    }
  }
}

.videoIndicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-family: $base-font-mono;
  font-size: 14px;
  line-height: 22px;
  margin-top: 40px;

  &.buffering {
    margin-top: -90px;
  }
}

.videoControls {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  margin-bottom: -54px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @include media('landscape', '<desktop') {
    margin-bottom: 0;
  }

  @include media('<tablet') {
    position: relative;
    z-index: 15; // fix video listQuality position vs arrows
  }

  &.halfWidth {
    width: calc(50% - 10px);

    &:nth-child(even) {
      left: auto;
    }
  }

  .commentsRangeContainer {
    width: 100%;
    height: 24px;
    z-index: 6;

    &.hidden {
      visibility: hidden;
    }

    .commentsRangeControls {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 10px;
    }

    .control {
      color: #999999;
      font-size: 20px;
      padding-top: 4px;
      cursor: ew-resize;
      &.left {
        margin-left: -1px;
      }
      &.right {
        margin-right: -3px;
      }
    }

    .commentsRangeIndicatorContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4px;
      width: 100%;
      .dot {
        width: 10px;
        min-width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--comments-range-color);
      }
      .commentIndicator {
        width: 100%;
        height: 4px;
        background-color: var(--comments-range-color);
        margin-left: -1px;
      }
    }
  }

  .videoTracks {
    height: 4px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    transition: height 0.15s linear;

    .videoTracksPadding {
      height: 16px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 5;
      &:hover {
        .hoveredTrack {
          display: block;
        }
      }
      .timeRangeIndicator {
        color: var(--comments-range-color);
        font-size: 8px;
        position: absolute;
        bottom: 0;
        &.left {
          margin-left: -1px;
        }
        &.right {
          margin-right: -2px;
        }
      }
    }

    .bufferedTrack,
    .hoveredTrack,
    .playbackTrack {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    .bufferedTrack {
      transition: width 0.2s linear;
      background: rgba(255, 255, 255, 0.2);
      z-index: 2;
      pointer-events: none;
    }
    .hoveredTrack {
      background: rgba(255, 255, 255, 0.2);
      margin: 10px 0 0;
      height: 6px;
      z-index: 3;
      display: none;
      pointer-events: none;
      .hoveredTime {
        position: absolute;
        bottom: 100%;
        right: -31px;
        margin-bottom: 12px;
        background: #000;
        border-radius: 5px;
        font-family: $base-font-mono;
        font-size: 14px;
        color: #ccc;
        pointer-events: none;
        height: 30px;
        line-height: 32px;
        width: 62px;
        text-align: center;
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #000 transparent transparent transparent;
          border-width: 7px 7px 0 7px;
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -7px;
        }
      }
      &.rightPart {
        .hoveredTime {
          right: 0;
          border-bottom-right-radius: 0;
          &::after {
            left: auto;
            right: 0;
            margin-left: 0;
            border-color: #000 #000 transparent transparent;
            border-width: 4px;
          }
        }
      }

      &.leftPart {
        .hoveredTime {
          right: auto;
          left: 0;
          border-bottom-left-radius: 0;
          &::after {
            left: 0;
            margin-left: 0;
            border-color: #000 transparent transparent #000;
            border-width: 4px;
          }
        }
      }
    }
    .playbackTrack {
      background: $brandColor;
      z-index: 4;
      &::after {
        content: '';
        position: absolute;
        right: -5px;
        top: -2px;
        width: 6px;
        height: 6px;
        background: $brandColor;
        border: 2px solid #fff;
        border-radius: 50%;
        display: none;

        @include media('<tablet') {
          width: 16px;
          height: 16px;
          top: -5px;
          right: -8px;
          opacity: 1;
        }
      }
    }
    .markersTrack {
      position: absolute;
      left: 0;
      top: -18px;
      width: 100%;
      height: 10px;
      .itemMarker {
        background: #fff;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(-50%);
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.6), 0 0 0 3px rgba(0, 0, 0, 0.3);
        .itemMarkerData {
          display: none;
          background: rgba(0, 0, 0, 0.95);
          border-radius: 5px;
          border: 1px solid #666;
          padding: 10px 12px;
          font-size: 12px;
          line-height: 1.5;
          position: absolute;
          left: 5px;
          bottom: 30px;
          width: 160px;
          transform: translateX(-50%);
          text-align: left;
          &::before {
            content: '';
            width: 12px;
            height: 12px;
            border-bottom: 1px solid #666;
            border-right: 1px solid #666;
            background: #000;
            display: block;
            transform: rotate(45deg);
            position: absolute;
            left: 50%;
            top: 100%;
            margin: -6px 0 0 -6px;
          }
          .itemMarkerDataTitle {
            color: #fff;
          }
          .itemMarkerDataText {
            color: #666;
            .itemMarkerDataTextTime {
              color: #ffcc00;
            }
          }
        }
        &:hover {
          background: #fff;
          .itemMarkerData {
            display: block;
          }
        }
        &.leftStickyMarker {
          .itemMarkerData {
            transform: translateX(0);
            &::before {
              display: none;
            }
          }
        }

        &.rightStickyMarker {
          .itemMarkerData {
            transform: translateX(-100%);
            &::before {
              display: none;
            }
          }
        }
      }
    }
    &:hover {
      height: 6px;
      .videoTracksPadding {
        height: 22px;
        bottom: -6px;
      }
      .playbackTrack {
        &::after {
          display: block;
        }
      }
    }
  }
  .btnsVideoControls {
    display: flex;
    height: 50px;
    align-items: stretch;

    /** hardcode: data-popper-reference-hidden - works incorrectly in this case */
    .PicsioPopper--openned.PicsioPopper--hidden[data-popper-reference-hidden="true"] {
      visibility: inherit;
      pointer-events: all;
    }

    .btnsLeft,
    .btnsRight,
    .btnsThumbnailControls {
      display: flex;

      &.btnsRight {
        margin-left: auto;
      }

      .timeCodeSelector {
        z-index: 3;
        margin-left: 17px;
        display: flex;
        align-items: center;

        .react-select {
          margin-bottom: unset;
          width: 105px;
          font-size: 15px;
        }
      }
    }

    .btnsThumbnailControls {
      @include media('<tablet') {
        display: none;
      }
    }

    .btnPlayVideo,
    .btnVolumeVideo,
    .btnCaptureVideoScreen,
    .btnCreateVideoThumbnail,
    .btnUploadVideoThumbnail,
    .btnCrop,
    .btnShowSubtitle,
    .btnDiffShowSubtitle,
    .btnFullscreenVideo,
    .qualityVideo,
    .playerGearMenu,
    .playerThumbnailMenu {
      height: 100%;
      width: 50px;
      cursor: pointer;
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      color: var(--video-button-color);
      font-size: 24px;
      [class*='svg-icon'],
      &::before,
      &::after {
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        [class*='svg-icon'],
        &::before,
        &::after {
          color: #fff;
          transform: scale(1.1);
        }
      }
      &.btnPlayVideo {
        &:hover {
          &::before {
            border-color: transparent transparent transparent #fff;
          }
        }
        &.playing {
          &:hover {
            &::before,
            &::after {
              background: #fff;
            }
          }
        }
      }
      &.qualityVideo {
        cursor: default;
      }
      &.inProgress {
        opacity: 0.5;
        animation: rotate-icon 1.2s infinite ease-in-out;
        pointer-events: none;
        cursor: default;
      }
      &.showSubtitle {
        [class*='svg-icon'] {
          color: var(--primary-main-color);
        }
      }
    }

    .btnShowSubtitle.disabled,
    .btnDiffShowSubtitle.disabled {
      cursor: default;

      [class*='svg-icon'],
      &::before,
      &::after {
        color: inherit !important;
        transform: none !important
      }
    }

    @keyframes rotate-icon {
      0% {
        transform: perspective(120px) rotateY(0deg);
      }
      100% {
        transform: perspective(120px) rotateY(-360deg);
      }
    }

    .btnPlayVideo {
      &::before {
        content: '';
        border-style: solid;
        border-width: 10px 0 10px 18px;
        border-color: transparent transparent transparent
          var(--video-button-color);
        width: 0;
        height: 0;
        display: block;
      }
      &.playing {
        &::before,
        &::after {
          content: '';
          border: none;
          width: 6px;
          height: 20px;
          margin-right: 5px;
          background: var(--video-button-color);
        }
      }
    }
    .btnVolumeVideo {
      position: relative;
      &:hover + .rangeVolumeVideo {
        width: 100px;
        padding: 0 10px;
      }
    }
    .rangeVolumeVideo {
      width: 0;
      overflow: hidden;
      display: flex;
      position: relative;
      backface-visibility: hidden;
      transition: width 0.2s linear, padding 0.2s linear;

      input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $brandColor;
      }

      input::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $brandColor;
      }

      input {
        width: 100%;
        margin: 0;
        margin: auto;
        -webkit-appearance: none;
        background: none;
      }
      &:hover,
      &.showSlider {
        width: 100px;
        padding: 0 10px;
      }
      &::after {
        content: '';
        width: calc(100% - 20px);
        height: 8px;
        background: #fff;
        border: 2px solid #ccc;
        border-radius: 4px;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -4px;
        z-index: -1;
        box-sizing: border-box;
      }
    }
    .timeVideo {
      font-family: $base-font-mono;
      font-size: 14px;
      color: var(--video-button-color);
      display: flex;
      align-items: center;
      padding-left: 5px;
      .timeCurrentVideo {
        flex: none;
      }
      .timeSeparatorVideo {
        padding-left: 5px;
        padding-right: 5px;
      }
      .timeFullVideo {
        flex: none;
      }
    }
    .qualityVideo {
      position: relative;
      z-index: 2;
      font-family: $base-font-mono;
      font-size: 14px;
      color: var(--video-button-color);
      width: 60px;
      justify-content: flex-start;
      padding-left: 20px;

      @include media('<tablet') {
        width: 100px;
      }

      .listQuality {
        display: none;
        position: absolute;
        left: 0;
        bottom: 100%;
        background: rgba(36, 36, 36, 0.8);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        width: 90px;
        text-align: left;
        &:empty {
          display: none;
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 10px 0;
          &::before {
            content: '';
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: rgba(36, 36, 36, 0.8) transparent transparent
              transparent;
            position: absolute;
            top: 100%;
            left: 25px;
          }
          li {
            margin: 0;
            padding: 0 0 0 18px;
            height: 35px;
            line-height: 35px;
            cursor: pointer;
            &.act {
              background: $brandColor;
              color: #000;
              cursor: default;
              &:hover {
                color: #000;
              }
            }
            &:hover {
              color: $brandColor;
            }
          }
          &:empty {
            padding: 0;
            &::before {
              display: none;
            }
          }
        }
      }
      &:hover {
        .listQuality {
          display: block;
        }
      }
    }
  }
  &.cropIsAct {
    .cropVideoControls {
      display: block;
    }
    .btnsVideoControls {
      .btnCrop {
        color: $brandColor;
        &:hover {
          [class*='svg-icon'],
          &::before {
            color: $brandColor;
          }
        }
      }
    }
  }

  .cropVideoControls {
    position: absolute;
    left: 0;
    bottom: 50px;
    z-index: 1;
    width: 100%;
    display: none;
    height: 50px;

    &:not(.screenshotedTimeline) {
      &:hover {
        z-index: 5;
      }
    }

    .trackToCrop {
      background: $brandColor;
      margin: 0 100% 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0 10px 0;
      position: relative;
      z-index: 3;
      height: 100%;
      box-sizing: border-box;
      cursor: grab;
      .leftTrackSide,
      .rightTrackSide {
        border-right: 2px dotted #999;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        height: 100%;
      }
      .leftTrackSide {
        left: 2px;
      }
      .rightTrackSide {
        right: 2px;
      }
      .txtRangeToCrop {
        font-family: $base-font-mono;
        font-size: 14px;
        color: #000;
        span {
          &:first-child::after {
            content: 'min';
            margin: 0 12px 0 8px;
          }
          &:last-child::after {
            content: 'sec';
            margin: 0 0 0 8px;
          }
        }
      }
      .btnDownloadCropped {
        font-size: 14px;
        color: #000;
        cursor: pointer;
        position: relative;
      }
      .btnLeftMarkerToCrop,
      .btnRightMarkerToCrop {
        width: 56px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
        cursor: ew-resize;
        &.timeCode {
          width: 100px;
        }
        input {
          display: block;
          margin: -32px 0 0 0;
          padding: 3px 0;
          line-height: 19px;
          height: 25px;
          font-family: $base-font-mono;
          font-size: 13px;
          width: 100%;
          text-align: center;
        }
        &::before {
          content: '';
          width: 0;
          height: 0;
          display: block;
          border-style: solid;
          border-width: 5px 4px 0 4px;
          border-color: #353535 transparent transparent transparent;
          position: absolute;
          left: 50%;
          top: -7px;
          margin-left: -4px;
        }
      }
      .btnLeftMarkerToCrop {
        left: 0;
        transform: translateX(-50%);
        &.toRight {
          transform: translateX(0);
          &::before {
            left: 0;
          }
        }
        &.toLeft {
          transform: translateX(-100%);
          left: 7px;
          &::before {
            left: 100%;
            margin-left: -8px;
          }
        }
      }
      .btnRightMarkerToCrop {
        right: 0;
        transform: translateX(50%);
        &.toLeft {
          transform: translateX(0);
          &::before {
            left: 100%;
          }
        }
        &.toRight {
          transform: translateX(100%);
          right: 7px;
          &::before {
            left: 0;
            margin-left: 0px;
          }
        }
      }
      &.smallTrackToCrop {
        .txtRangeToCrop {
          position: absolute;
          padding: 0;
          z-index: 2;
          font-size: 10px;
          line-height: 14px;
        }
        .btnDownloadCropped {
          top: -58px;
          height: 56px;
        }
      }
    }
    .videoScreenshots {
      position: absolute;
      left: 0;
      top: 26px;
      width: 100%;
      height: calc(100% - 26px);
      display: flex;
      background: #000;
      //&::before {
      //	content: 'Loading...';
      //	font-size: 20px;
      //	text-align: center;
      //	position: absolute;
      //	left: 50%;
      //	top: 50%;
      //	z-index: 1;
      //	color: #fff;
      //	margin: -10px 0 0 -43px;
      //}
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-image: linear-gradient(to right, rgba(136,136,136,0.5) 0%, rgba(136,136,136,0.5) 99%, transparent 99%, transparent 100%);
        //animation: timelineAnimation 2s .5s infinite;
        //background-size: (100% / 3);

        @media screen and (min-width: 480px) {
          background-size: calc(100% / 3);
        }

        @media screen and (min-width: 640px) {
          background-size: calc(100% / 4);
        }

        @media screen and (min-width: 1024px) {
          background-size: calc(100% / 6);
        }

        @media screen and (min-width: 1280px) {
          background-size: calc(100% / 7);
        }

        @media screen and (min-width: 1440px) {
          background-size: calc(100% / 8);
        }
      }
      .itemVideoScreenshots {
        height: 100%;
        flex: 0 1 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #000;
        border-right: 1px solid #000;
        border-left: 1px solid #000;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
      }
    }
    &.screenshotedTimeline {
      height: 121px;
    }
    .trackToCrop {
      background: none;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      padding: 0;
      border-top: 2px solid $brandColor;
      border-bottom: 2px solid $brandColor;
      height: calc(100% - 26px);
      margin-top: 26px;
      .txtRangeToCrop {
        padding: 0 0 0 10px;
        height: 26px;
        line-height: 28px;
        background: $brandColor;
        width: 110px;
        margin-top: -26px;
        span:first-child::after {
          margin: 0 5px;
        }
        span:last-child::after {
          margin: 0 0 0 5px;
        }
      }
      .btnDownloadCropped {
        padding: 0 10px;
        height: 26px;
        line-height: 28px;
        background: $brandColor;
        top: -26px;
      }
      .leftTrackSide,
      .rightTrackSide {
        border-color: $brandColor;
        z-index: 2;
      }
      .leftTrackSide {
        left: -1px;
      }
      .rightTrackSide {
        right: -1px;
      }
      &::before,
      &::after {
        content: '';
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: -2px;
        height: 100%;
        width: 10000px;
        padding-bottom: 4px;
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
      &.middleTrackToCrop {
        .txtRangeToCrop {
          width: auto;
          span:first-child::after {
            margin: 0;
          }
        }
      }
    }
  }
}

.playbackRate {
  font-family: 'Fira Mono', monospace;
  font-size: 14px;

  .PicsioMenuItem__text {
    font-family: 'Fira Mono', monospace;
  }

  .MenuOnHoverOpener {
    color: var(--video-button-color);
  }
}

.previewInfobox {
  float: none;
  height: 0;
  background: #242424;
  position: relative;
  left: 0;
  top: 50px;
  bottom: 0;
  &:not(:empty) {
    height: calc(100% - 50px);
  }
  @media screen and (max-width: $mediaDevice) {
    position: absolute;
    z-index: 5;
    width: 100%;
    border-left: 0;
  }

  .resizer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    cursor: ew-resize;
    z-index: 1400;

    @include media('<tablet') {
      display: none;
    }
  }
}

.picsioThemeLight {
  .preview {
    background: rgba(255, 255, 255, 1);
    .wrapperGallery {
      .previewViewSpinner {
        background: rgba(255, 255, 255, 0.9);
      }
      .galleryArrowPrev,
      .galleryArrowNext {
        &:hover span {
          background: rgba(0, 0, 0, 0.25);
        }
      }
      .containerMediaFile {
        .innerContainerMediaFile {
          .tempBoundingBoxTextContainer {
            background: #f4f4f4;
            .text {
              color: #3b94fb;
            }
          }
        }
      }
    }

    .commentHistoryMarker {
      background: #fff;
      color: #666;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

      .authorHistoryMarker {
        color: #000;
      }

      a {
        color: $brandColorLightTheme;
      }
    }
  }
  .previewInfobox {
    background: #fff;
    border-left-color: #ddd;
  }
  .diffContainerInner {
    background: white;
  }

  .videoControls {
    .videoTracks {
      background: rgba(0, 0, 0, 0.2);
      .bufferedTrack {
        background: rgba(0, 0, 0, 0.2);
      }
      .hoveredTrack {
        background: rgba(0, 0, 0, 0.2);
      }
      .playbackTrack {
        &::after {
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        }
      }
      .markersTrack {
        .itemMarker {
          background: $brandColorLightTheme;
        }
      }
    }

    .btnsVideoControls {
      .rangeVolumeVideo {
        input::-webkit-slider-thumb {
          background: $brandColorLightTheme;
        }

        input::-moz-range-thumb {
          background: $brandColorLightTheme;
        }
      }

      .btnPlayVideo,
      .btnVolumeVideo,
      .btnCaptureVideoScreen,
      .btnCreateVideoThumbnail,
      .btnUploadVideoThumbnail,
      .btnCrop,
      .btnShowSubtitle,
      .btnDiffShowSubtitle,
      .btnFullscreenVideo,
      .qualityVideo,
      .playerGearMenu,
      .playerThumbnailMenu {
        &:hover {
          [class*='svg-icon'],
          &::before,
          &::after {
            color: #000;
          }
        }
        &.btnPlayVideo {
          &:hover {
            &::before {
              border-color: transparent transparent transparent #000;
            }
          }
          &.playing {
            &:hover {
              &::before,
              &::after {
                background: #000;
              }
            }
          }
        }
      }

      .btnPlayVideo {
        &.playing {
          &::before,
          &::after {
            background: #000;
          }
        }
      }
      .qualityVideo {
        .listQuality {
          background: #fff;
          ul {
            &::before {
              border-color: #fff transparent transparent transparent;
            }
            li {
              &:hover {
                color: $brandColorLightTheme;
              }
              &.act {
                background: $brandColorLightTheme;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.markersContainer {
  top: 0;
  position: absolute;
  visibility: hidden;
  text-align: left;

  > div {
    visibility: visible;
  }
  .tmpMarker {
    .itemHistoryMarker {
      margin: -19px 0 0 -19px !important;
      border-radius: 50% !important;

      .circleHistoryMarker {
        border: 5px solid rgba(255, 255, 255, 0.8) !important;
        background-clip: content-box !important;
        cursor: move !important;
      }
    }
  }
}
.removeHistoryMarker {
  position: absolute;
  display: none;
  right: 24px;
  top: -10px;
  width: 18px;
  height: 18px;
  line-height: 17px;
  font-size: 19px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 50%;
  transform: rotate(45deg);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    color: firebrick;
  }
}
.itemHistoryMarker:hover .removeHistoryMarker {
  display: block;
  z-index: 3;
}
.diffContainer {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 50%;
  top: 0;
  bottom: 0;

  img,
  canvas {
    cursor: move;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    transition: opacity 0.5s ease;
    transform-origin: center center;
    will-change: transform;
  }
}
.diffPlaceholder {
  display: flex;
  height: 100%;
  background: black;
  text-align: center;
  font-size: 33px;
  font-style: normal;
  color: #242424;
  align-items: center;
  justify-content: center;
}
.diffContainerInner {
  display: flex;
  height: 100%;
  background: black;
}

.diffLine {
  position: absolute;
  white-space: nowrap;
  left: 50%;
  width: 1px;
  background: #ccc;
  top: 0;
  bottom: 0;
  z-index: 2;
  color: white;
  font-size: 20px;
  cursor: col-resize;

  &::before {
    content: '. . .';
    color: black;
    width: 10px;
    height: 30px;
    position: absolute;
    left: -5px;
    top: 50%;
    font-size: 18px;
    line-height: 6px;
    white-space: normal;
    padding: 1px 3px;
    box-sizing: border-box;
    border-radius: 3px;
    margin: -15px 0 0;
    background: #ccc;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  }

  &::after {
    content: '';
    width: 30px;
    margin-left: -15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;

    @include media('<desktop') {
      width: 50px;
      margin-left: -25px;
    }
  }

  span {
    position: absolute;
    left: 10px;
    font-size: 18px;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    height: 30px;
    line-height: 30px;
    margin: 15px 0 0;
    padding: 0 10px;

    &:nth-child(even) {
      left: auto;
      right: 10px;
    }
  }
}

.obj-error {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
}
