.PlaceholderMediaMatch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;

  .title {
    color: #999;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #aaa;
  }
}
