
:root,
html.picsioThemeDark {
  --input-background-color: #423d2e;
}

html.picsioThemeLight {
  --input-background-color: #e1efff;
}


.addFilters {
  width: 100%;
  display: block;
  background: inherit;
  box-sizing: border-box;

  &__icon-close {
    cursor: pointer;
  }

  &__input.PicsioInput--md {
     & .PicsioInputAdornment {
       padding: 8.5px 10.5px;
     }

    & .PicsioInput__input {
    padding: 8.5px 0;

    &::placeholder {
       font-family: 'Fira Sans';
       font-style: normal;
       font-weight: 300;
       font-size: 14px;
       line-height: 17px;
       color: #999999;
     }
   }
  }
}


.exportDialog {
  &-checkboxes {
    margin: 0 !important;


    li {
      padding-top: 6px !important;
      padding-bottom: 12px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  .simpleDialogContent {
    padding-left: 0px !important;
    margin-top: unset !important;
    .simpleDialogContentInner {
      p {
        margin: unset !important;
        padding-bottom: 6px;
      }
    }
  }

}

.subtext {
  color: #666;
  font-size: 16px;
  font-weight: 300;
}

.searchIcon {
  padding: 0 10px;
  width: 22px;
  height: 22px;

    path {
      fill: #ccc;
    }
}



.checked {
  background-color: var(--input-background-color);
}
