.TranscriptSearch {
  width: 100%;
  display: block;
  background: inherit;
  box-sizing: border-box;

  &__icon-close {
    cursor: pointer;
  }

  &__input.PicsioInput--md {
     & .PicsioInputAdornment {
       padding: 8.5px 10.5px;
     }

    & .PicsioInput__input {
    padding: 8.5px 0;

    &::placeholder {
       font-family: 'Fira Sans';
       font-style: normal;
       font-weight: 300;
       font-size: 14px;
       line-height: 17px;
       color: #999999;
     }
   }
  }
}
