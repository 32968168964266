@import '../../../css/mixins';

.importPlaceholder {
  display: flex;
  flex: 1;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  line-height: 34px;
  font-weight: 200;
  flex-direction: column;

  &__btnBack {
    position: absolute !important;
    top: 50px;
    left: 50px;
  }

  .importPlaceholderTitle {
    margin-bottom: 80px;
    text-align: center;
    font-family: 'Fira Sans';
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-contrast-color);
  }

  .innerContainer {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    height: 200px;
    width: 200px;
    color: #ccc;
    border-radius: 8px;
    background: #212121;
    transition: all 0.3s ease;
    padding: 20px;
    padding-top: 48px;

    .picsioThemeLight & {
      background: rgba($color: #000000, $alpha: 0.05);
    }

    &:hover {
      color: #fff;
      background: #2e2e2e;
      cursor: pointer;

      .picsioThemeLight & {
        background: rgba($color: #000000, $alpha: 0.1);
      }

      .btnIconUpload .PicsioIcon {
        color: #ffcc00;
      }
    }

    .btnIconUpload .PicsioIcon {
      color: #ccc;
      transition: all 0.3s ease;
    }

    & .labelBeta {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 2;
    }
  }

  input {
    position: absolute;
    left: -150%;
    top: 0;
    opacity: 0.01;
    width: 250%;
    height: 100%;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .btnIconUpload {
    font-size: 50px;
    color: #fff;
    margin: 0 0 15px;
    &::before {
      color: #fff;
    }
  }

  .placeholderTitle {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
  }
}

@include media('<phone') {
  #import {
    .importPlaceholder {
      height: calc(100% - 145px);
      padding: 0 15px;
      display: flex;
      flex-direction: column;
    }

    .innerContainer {
      flex-direction: column;
    }
  }
}

@include media('<tablet') {
  #import {
    .importPlaceholder {
      height: calc(100% - 145px);
      padding: 0 15px;
    }
  }
}

@media only screen and (max-width: 414px) {
  .importPlaceholder {
    .placeholderTitle {
      font-size: 30px;
      line-height: 34px;
    }
  }
}


/*************************************/
/************ LIGHT THEME ************/
/*************************************/
.picsioThemeLight {
  .importPlaceholder {
    .inner {
      color: #999;
      &:hover {
        color: #242424;
      }
    }
  }
}
