.notifications {
	min-height: 400px;
	cursor: default;
	display: flex;
	flex-direction: column;
	color: #ccc;
	font-size: 14px;
	padding: 0;

	@include media('<tablet') {
		height: 100%;
	}

	@include media('>=tablet') {
		width: 340px;
		max-height: 60vh;
	}
}

.notificationsTopBar {
	padding: 0 0 8px 0;
	margin: 0 20px;
	border-bottom: 1px solid var(--separate-border-color);
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	.notificationsTitle {
		position: relative;
		font-size: 14px;
		font-weight: 400;
		flex: 1;
	}

	.picsioDefBtn {
		min-width: 0;
		padding: 0 20px;
		margin-left: auto;

		.icon {
			margin-right: 4px;
		}
	}

  @include media('<desktop') {
		padding: 10px 0;
	}
}

.notificationsButtons {
	flex: 0 0 auto;
	margin: 0 20px;
	padding-top: 10px;
	display: flex;
	text-align: center;
	justify-content: center;
	margin-top: auto;
	flex-direction: column;
	border-top: 1px solid var(--separate-border-color);

	.picsioDefBtn {
		margin: 0 5px;
	}

	.picsioLink {
		padding: 0 10px;
		line-height: 30px;
		color: inherit
	}

	@include media('<desktop') {
		padding: 10px 0;

		.picsioLink {
			margin-top: 6px;
		}
	}
}

.notificationsPlaceholder {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex: 1;
	padding: 20px 0;
	margin: 0 20px;
}

.notificationsPlaceholderIcon {
	font-size: 70px;
	margin-bottom: 25px;
	color: #dadada;
}

.notificationsPlaceholderText {
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 15px;
	font-weight: 300;
}


.notificationsItems {
  max-height: 522px;
	overflow: auto;
	flex: 1;
	padding: 0 20px;
}

.notificationsItem {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 10px 0 12px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -20px;
		right: -20px;
		background-color: rgba(0,0,0,0.1);
		opacity: 0;
		z-index: -1;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}

	&:not(:last-child) {
		border-bottom: 1px solid rgba(255,255,255,0.07);
	}
}

.notificationsItemsHolder {
	position: relative;
}

.notificationsItemStatus {
	position: absolute;
	top: 5px;
	left: -5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: $brandColor;
	z-index: 1;
}

.notificationsItemVisual {
	flex: 0 0 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	background: rgba(0,0,0,0.2);

	img {
		max-width: 100%;
		max-height: 100%;
		height: auto;
	}
}

.notificationsItemThumbnail {
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.thumbnailTrashed {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		pointer-events: none;

		.PicsioIcon,
		.svg-icon {
			position: absolute;
			z-index: 1;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.7);
		}
	}
}

.notificationsItemContent {
	flex: 1 0 100px;
	padding: 0 0 0 13px;
	word-wrap: break-word;
	overflow: hidden;

	@include media('>=tablet') {
		max-width: 220px; // 300 - 2*20 - 80
	}
}

.notificationsItemText {
	line-height: 17px;
}

.notificationsItemTime {
	flex: 1 0 100%;
	margin-top: 10px;
	font-size: 10px;
	line-height: 10px;
}

.initiator {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
	&.hidden {
		display: none;
	}
}

.initiatorName {
	line-height: 17px;
	color: #fff;
	padding: 0 10px 0 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.initiatorAvatar {
	width: 25px;
	height: 25px;
}

.positiveColor {
	color: $positive;
}

.negativeColor {
	color: $negative;
}

.picsioThemeLight {
	.notifications {
		color: #666;
	}

	.notificationsTitle,
	.notificationsItems,
	.notificationsButtons {
		border-color: rgba(0, 0, 0, 0.07);
	}

	.notificationsItemVisual {
		background: rgba(0,0,0,0.07);
		color:rgba(0,0,0,.24)
	}

	.notificationsItemThumbnail {
		&.thumbnailTrashed {
			.svg-icon {
				color: #fff;
			}

			&::after {
				background: rgba(0,0,0,0.27);
			}
		}
	}

	.initiatorName {
		color: #000;
	}

	.positiveColor {
		color: $positiveColorLightTheme;
	}
}
