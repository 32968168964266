.aiButtons {
  margin: 14px 0;
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: flex-end;

  > :global(.PicsioButton) {
    flex: 1;
  }

  :global(.PicsioIconButton.PicsioButton--size--default) {
    width: 42px;
    height: 30px;
    min-height: 30px;
    color: var(--primary-button-color);
    background: var(--primary-button-bg-color);
    border-color: var(--primary-button-bd-color);

    &:hover {
      color: var(--primary-contrast-color) !important;
      background: var(--primary-button-bg-hover-color) !important;
      border-color: var(--primary-button-bd-hover-color);
    }
  }
}
