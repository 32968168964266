.list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  line-height: 26px;

  li {
    padding: 3px 31px 3px 0;
    position: relative;
  }

  .removeButton {
    position: absolute;
    top: 3px;
    right: 0;
  }
}

.active {
  color: var(--secondary-contrast-color);
  font-weight: 500;
}

.nameHolder {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: default;
}

.name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
