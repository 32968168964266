.menuItem {
  display: flex;
  justify-content: space-between;
  min-width: 274px;
  padding: 8px 16px;
  gap: 10px;

  .menuItemContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.subMenuItem {
  align-items: center;
  text-align: start;
  min-width: 170px;
  padding-left: 50px !important;

  .subMenuIcon {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 25px;
  }
}

.subMenuItemBack {
  border-bottom: 1px solid #333;

  :global(.picsioThemeLight) & {
    border-bottom: 1px solid #f1f1f1;
  }
}

.submenuWrapper {
  overflow: auto;
  max-height: 40vh;
}
