.TranscriptMenu {
  &__button {
    margin-left: 3px;
  }

  &__button.isActive i {
    background: #303030;

    .picsioThemeLight & {
      background: #f4f4f4;
    }
  }
}
