.wrapperRadiosDialog {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1001; // was 4

	&:empty {
		display: none;
	}
	.radiosDialog__text {
    margin: 0 0 20px;
  }

	.radiosDialog__list {
		padding: 0;
		margin: 0 0 20px;
		list-style: none;

		li {
			display: flex;
			flex-flow: row nowrap;
			margin: 0 0 16px;
		}
		.UIRadio__input {
			margin-right: 9px;
		}
		.UIRadio__label {
			font-family: $base-font;
			font-weight: 400;
			font-size: 12px;

      .description {
        color: var(--secondary-dark-color);
      }
		}
		.svg-icon {
			display: block;
			margin-top: -8px;
			color: #333;
		}
		b {
			font-weight: 400;
			display: inline-block;
			margin-bottom: 5px;
			font-size: 14px;

      span {
        color: #666;
        .picsioLightTheme & {
          color: #9b9b9b;
        }
      }
		}

		&.hasDescription {
			margin: 0 0 15px;
		}
	}

	.radiosDialog__icon {
		width: 50px;
		text-align: right;
		font-size: 60px;
		margin: 0 45px 0 -5px;
		flex-shrink: 0;

		&:empty {
			display: none;
		}

		@media screen and (max-width: $mediaDevice) {
			margin-right: 25px;
		}
	}
}
