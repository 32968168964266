@import 'cssVariables';

.List {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
  }

  .leftGroupLabel,
  .rightGroupLabel {
    display: flex;
  }

  .leftGroupLabel {
    & div:first-child {
      margin-right: 14px;
    }
  }

  .rightGroupLabel {
    & div:first-child {
      margin-right: 30px;
    }
  }

  .label {
    color: #666;
    font-family: 'Fira Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .counter {
    color: #ccc;

    :global(.picsioThemeLight) & {
      color: #666;
    }
  }

  .listWrapper {
    position: relative;
    height: 50vh;
    overflow: auto;
  }

  .list {
    position: relative;
    padding: 0;
    margin: 0;
  }

  .emptyList {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .listItemsWrapperHidden {
    height: 0;
  }

  .even {
    background-color: #1f1f1f;

    :global(.picsioThemeLight) & {
      background-color: #f4f4f4;
    }
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
