.simpleFaceDialogContent {
  display: flex;
  padding: 0 48px;
  flex-direction: column;
  margin: 40px 0 56px 0;
}

.simpleFaceDialogHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 300;
  color: #fff;
}

.simpleFaceDialogBtnCross {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 13px 13px 5px 13px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    color: #ff6600;
  }
}
.picsioThemeLight {
  .simpleFaceDialogTitle {
    color: #000;
  }
}

.faceTextfield.faceTextfield {
  margin-bottom: 16px;
  label {
    color: #fff;
    margin-bottom: 9px;
  }
}
